<template>
  <div class="ingredients-form">
    <h3><span>Group Ingredients</span></h3>
    <div class="recipe-ingredients">
      <div class="recipe-ingredient" v-for="ingredient in ingredients" :key="ingredient.id+'_'+(ingredient.ingredient?ingredient.ingredient.id:ingredient.ingredient_id)">
        <div class="name"><router-link :to="'/plan-ingredient/'+ingredient.id">{{ingredient.name}}</router-link></div>
        <div class="actions" v-if="deleteConfirmation !== ingredient.id && !passive">
          <div class="danger-btn" @click="confirmDelete(ingredient)">x</div>
        </div>
        <div class="confirm-delete flexy" v-if="deleteConfirmation === ingredient.id">
            <span>Warning: This will remove this ingredient from all Preference Groups</span>
            <button @click="deleteIngredient(ingredient)">Yes</button>
            <button @click="deleteConfirmation = null">No</button>
        </div>
      </div>
    </div>
    <div v-if="!ingredients || ingredients.length === 0" class="recipe-ingredient">
      No ingredients
    </div>
    <div class="add-ingredient box" v-if="!passive">
      <h3>Add Ingredient</h3>
      <div class="flexy">
        <div class="diagonal-wrap flex-1">
          <v-select v-model="newIngredient" :options="allPlanIngredients" label="name" :reduce="it => it.id" placeholder="Select Ingredient" />
        </div>

        <div v-if="existing(newIngredient)" class="exists-error">This ingredient already exists in this group</div>

        <div class="flexy">
          <div :class="'diagonal-wrap'+(!existing(newIngredient) && newIngredient?'':' disabled')">
            <button @click="addIngredient">Add Ingredient</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GroupIngredientForm",
  props: ['ingredients','passive'],
  data() {
    return {
      deleteConfirmation: null,
      newIngredient : '',
      id : null
    }
  },

  computed : {
    ...mapGetters(['allPlanIngredients']),
  },

  methods : {
    ...mapActions(['getPlanIngredients']),

    confirmDelete(ingredient) {
      this.deleteConfirmation = ingredient.id;
    },

    deleteIngredient(ingredient) {
      this.$emit('delete', ingredient)
      this.deleteConfirmation = null;
    },

    existing(id) {
      return !!(this.ingredients.find(it => it.id === id))
    },

    addIngredient() {
      if (this.newIngredient && !this.existing(this.newIngredient)) this.$emit('add', this.allPlanIngredients.find(it => it.id === this.newIngredient))
      this.newIngredient = '';
    },

    async initData() {
      if (this.allPlanIngredients.length <= 0)await this.getPlanIngredients();
    }
  },

  mounted() {
    this.initData();
  },

}
</script>
