<template>
  <ul class="tag-list">
    <li v-for="tag in tags" :key="tag.tag_id" class="tag">
      <span>{{tagValue(tag)}}</span>
      <b v-if="secondary" class="tag-secondary">{{tag[secondary]}}{{secondarySuffix||''}}</b>
      <span v-if="!passive" class="close-btn" @click="() => removeTag(tag.id)"></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TagList",
  props : ['allTags', 'tagKey', 'tags', 'prop','secondary','secondarySuffix','passive'],
  methods : {
    removeTag(id) {
      this.$emit('remove-tag', id)
    },
    tagValue(t) {
      const tag = this.allTags.find(it => it.id === t[this.tagKey]);
      if (tag) return tag[this.prop]
    },
  }
}
</script>
