<template>
  <div id="app">
    <Navbar />
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import Navbar from "@/components/ui/Navbar";
import 'vue-select/dist/vue-select.css';
import './scss/style.scss';

export default {
  name: 'App',

  methods: {
    ...mapActions(['checkLogin'])
  },

  components : {
    Navbar
  },

  async mounted() {
    await this.checkLogin()
  },

}
</script>
