<template>
  <div class="locale-form">
    <div class="box-header"><p>Locales</p></div>
    <div class="locales" v-if="!settingLocale">
      <div class="locale" v-for="locale in allLocales" :key="locale.id" @click="settingLocale = locale">
        <h3><div :class="'btn ' + (locales?(hasLocale(locale)?'success-btn':'danger-btn'):'disabled-btn')"></div> {{locale.description}}</h3>
        <div v-if="hasLocale(locale)" class="locale-inner"><span class="bold">{{getLocale(locale)[nameField || 'name']}}</span>
          <small v-if="secondary">{{getLocale(locale)[secondary]}}</small></div>
      </div>
    </div>
    <div v-if="settingLocale">
      <SideForm :fields="fields" :data="getLocale(settingLocale)" @submit="doSubmit" @cancel="settingLocale = false" />
    </div>
  </div>
</template>

<script>
import SideForm from "@/components/ui/SideForm";
export default {
  name: "LocaleForm",
  props: ['onSubmit','locales','allLocales','fields','nameField','secondary'],
  components: {SideForm},
  data() {
    return {
      settingLocale: null
    }
  },
  methods : {
    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },
    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    doSubmit(data) {
      this.onSubmit(data, this.settingLocale.id)
      this.settingLocale = null
    }
  }
}
</script>
