<template>
  <Draggable v-model="dragOrder" class="draggable-list" @end="onDragEnd" handle=".handle-icon">
    <div v-for="(item, index) in items" :key="item.id" class="form-group active">
      <span class="handle-icon"></span>
      <span>{{index+1}}</span>
      <b>{{item[prop]}}</b>
    </div>
  </Draggable>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  name: "SortableList",
  props: ['items','prop'],
  components : {Draggable},
  data() {
    return {
      dragOrder: []
    }
  },
  methods : {
    onDragEnd(data) {
      this.$emit('sort', data)
    }
  },
}
</script>

<style scoped>

</style>
