<template>
  <div>
    <div class="flexy">
      <div class="diagonal-wrap main">
        <input type="text" v-model="newName" placeholder="Plan Meal Name">
      </div>
    </div>
    <div class="flexy">
      <div class="main">
        <v-select v-model="newPlanMealType" :options="allMealTypes" :reduce="it=>it.id" label="name" placeholder="- Select Meal Type -" :clearable="false" />
      </div>
      <div class="flexy end padded">
        <div>
          <input type="checkbox" :checked="newPlanMealIsSpecial" @change="newPlanMealIsSpecial = !newPlanMealIsSpecial" /> Special
        </div>
        <div :class="'diagonal-wrap'+(newName&&newPlanMealType?'':' disabled')">
          <button :disabled="!newName || !newPlanMealType" @click="onSubmit">Add</button>
        </div>
        <button @click="cancel" class="unwrap">Cancel</button>
      </div>
    </div>
    <NutritionCounter label="Meal Nutrients" :ingredients="data.ingredients" :compact="true" />
  </div>
</template>

<script>
import NutritionCounter from "@/components/ui/NutritionCounter";
export default {
  name: "AddPlanMealForm",
  components: {NutritionCounter},
  props: ['submit','cancel','data','meta'],
  data() {
    return {
      newName: '',
      newPlanMealType: null,
      newPlanMealIsSpecial: false,
    }
  },
  computed : {
    addMeals() { return [{ ingredient: this.data, amount_g: 100, serving_amount: 1}] },
    allMealTypes() { return this.meta ? this.meta.allMealTypes:[] }
  },
  mounted() {
    this.newName = this.data.name;
    this.newPlanMealIsSpecial = this.data.is_special;
  },

  methods : {
    onSubmit() {
      this.submit(this.data.id, this.newName, this.newPlanMealType, this.newPlanMealIsSpecial);
    }
  }
}
</script>

<style scoped>

</style>
