<template>
  <div class="serving-size-form">
    <div class="form-input flexy">
      <h3>Add Size</h3>
        <div class="main">
          <v-select v-model="newSize" :options="servingSizes" label="name" :reduce="it => it.id" placeholder="Select Size" />
        </div>
        <input class="unwrap" type="number" v-model="newAmount" placeholder="Grams">
      <div :class="'diagonal-wrap square-wrap' + (canSubmit ? '':' disabled')">
        <button class="square" @click="addSize"><span class="icon-text">+</span></button>
      </div>
    </div>
    <div class="exists-error" v-if="existsError">This serving size already exists for this ingredient</div>
  </div>
</template>

<script>
export default {
  name: "AddServingSizeForm",
  props: ['servingSizes', 'existingSizes'],

  data() {
    return {
      newSize : '',
      newAmount: '',
      existsError: false
    }
  },
  methods : {
    addSize() {
      if (this.canSubmit) {
        this.$emit('add', this.newSize, this.newAmount)
        this.newSize = this.newAmount = ''
      }
    }
  },
  computed : {
    canSubmit() { return this.newSize && this.newAmount && !this.existsError }
  },
  watch : {
    newSize() {
      if (this.newSize) {
        const size = this.servingSizes.find(it => it.id === this.newSize)
        if (size) this.newAmount = size.amount_g;

        this.existsError = this.existingSizes && this.existingSizes.find(it => it.serving_size_id === this.newSize)
      }
      else {
        this.newAmount = ''
      }
    }
  }
}
</script>
