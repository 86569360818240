<template>
  <div class="tag-form">
    <div class="form-input flexy">
      <h3>Add {{type}}</h3>
      <div class="diagonal-wrap main">
        <v-select v-model="newItem" :options="items" :label="labelProp" :reduce="it => valueProp?it[valueProp]:it" :placeholder="placeholder?placeholder:'Select '+type" />
      </div>
      <div :class="'diagonal-wrap square-wrap' + (newItem ? '':' disabled')">
        <button class="square" @click="addItem"><span class="icon-text">+</span></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemAddForm",
  props: ['type','items','placeholder','labelProp','valueProp','add'],
  data() {
    return {
      newItem: ''
    }
  },
  methods : {
    addItem() {
      if (this.newItem) this.$emit('add', this.newItem)
    }
  }
}
</script>
