<template>
  <div class="histogram">
    <canvas class="histogram-inner" ref="canvas"></canvas>
  </div>
</template>
<script>
export default {
    name: "Histogram",
    props: ['data', 'attr', 'center', 'sampled', 'color'],
    data() {
        return {
        }
    },

    mounted() {
        this.cnv = this.$refs.canvas;
        this.ctx = this.cnv.getContext('2d');
        this.height = 90;
        this.width = this.cnv.getBoundingClientRect().width;
        this.cnv.setAttribute('width', this.width);
        this.cnv.setAttribute('height', this.height);

        this.drawHistogram(this.data, this.center);
    },

    watch : {
        center() {
            this.drawHistogram(this.data, this.center)
        },
        data() {
            this.drawHistogram(this.data, this.center)
        }
    },

    methods: {
        drawHistogram(data, center) {
            if (!data) return;
            this.ctx.clearRect(0,0, this.width, this.height);
            this.ctx.fillStyle = '#000000cc';
            this.ctx.fillRect(0, 0, this.width, this.height- 20);

            // borders
            this.ctx.fillStyle = '#ffffff88';
            this.ctx.fillRect(0,0, this.width, 1)
            this.ctx.fillRect(0,this.height-1, this.width, 1)
            this.ctx.fillRect(0,0, 1, this.height)
            this.ctx.fillRect(this.width-1,0, 1, this.height)
            this.ctx.fillRect(0,this.height-20, this.width, 1)
            this.ctx.fillRect(0,this.height-13, this.width, 1)


            let res = {}, dd, min = 99999999, max = -99999999, size, pos, lw = 1;
            for (let d of data) {
                dd = Math.round(d);

                if (dd < min) min = dd;
                if (dd > max) max = dd;

                if (!res[dd]) res[dd] = 0;
                res[dd]++;
            }

            size = Math.max(Math.abs(center-min), Math.abs(center-max));
            lw = Math.max(1, (this.width / 2.5) /size)

            // add center
            this.ctx.fillStyle = '#ffffff44';
            this.ctx.fillRect(Math.floor(this.width/2), 0, lw, this.height);



            this.ctx.fillStyle = this.color || '#ff0000';

            for (let x in res) {
                pos = (x - center)/(size);
                this.ctx.fillRect(this.width/2 + pos*this.width/2, this.height - 20 - res[x]*2, lw, res[x]*2 );
            }

            // add sampled
            this.ctx.fillStyle = '#000000cc';
            this.ctx.fillRect(1, this.height - 19, this.width-2, 6 );

            pos = (Math.round(this.sampled) - center)/(size);
            if (pos < -1 || pos > 1) {
                this.ctx.fillStyle = '#ff0000';
                this.ctx.fillRect(1, this.height - 19, this.width-2, 6 );
                this.ctx.fillStyle = '#ff000033';
                this.ctx.fillRect(1, 1, this.width-2, this.height -2 );
            }
            else {
                this.ctx.fillStyle = '#ffffff';
                this.ctx.fillRect(this.width/2 +  pos*this.width/2-1, this.height - 19, lw+2, 6 );

                this.ctx.fillStyle = this.color || '#ff0000';
                this.ctx.fillRect(this.width/2 + pos*this.width/2, this.height - 19, lw, 6 );
            }



            // !!! texts !!!
            // text bg
          this.ctx.fillStyle = '#000000cc'
          this.ctx.fillRect(1, this.height-12, this.width-2, 11)

            // fonts
            this.ctx.font = "10px Arial";
            this.ctx.fillStyle = '#ffffff';
            this.ctx.textAlign = 'left';
            this.ctx.fillText((center-size).toFixed(2)*1+'', 2, this.height - 3)
            this.ctx.textAlign = 'right';
            this.ctx.fillText((center+size).toFixed(2)*1+'', this.width-2, this.height - 3)
            this.ctx.textAlign = 'center';
            this.ctx.fillText(center.toFixed(2)*1+'', this.width/2, this.height - 3)
        }
    },

}
</script>
