<template>
    <div class="panel inline">
      <ConfirmModal v-if="userRoleForDeletion" item-type="User Role" :item-name="userRoleForDeletion.name" :confirm="confirmDeleteUserRole" :cancel="cancelDeleteUserRole" />
      <ListPanel title="User Roles" :fields="fields" :items="allUserRoles" :on-add="addNewUserRole" :on-delete="askToDeleteUserRole" :on-row="goto" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "UserRoles",
  components: {ListPanel, ConfirmModal },
  data() {
    return {
      userRoleForDeletion: null,
      search : "",
      fields : [
        {label: "Name", prop: "name", primary: true},
        {label: "Permissions", prop: "permissions", format: (v) => (v.map(it => it.permission)).join(", ") },
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allUserRoles']),
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allUserRoles.length, this.getUserRoles]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getUserRoles','setLoading','deleteUserRole']),
    goto(userRole) {
      this.$router.push('/role/'+userRole.id)
    },
    addNewUserRole() {
      this.$router.push('/role/new')
    },
    askToDeleteUserRole(userRole) {
      this.userRoleForDeletion = userRole;
    },
    cancelDeleteUserRole() { this.userRoleForDeletion = null },
    confirmDeleteUserRole() {
      this.deleteUserRole(this.userRoleForDeletion.id)
      this.userRoleForDeletion = null
    }
  },

}
</script>
