<template>
  <div class="ingredients-form">
    <h3><span>Meal Recipes</span></h3>
    <div class="recipes">
      <div class="recipe-container" v-for="recipe in recipes" :key="recipe.id">
        <div class="recipe">
          <div class="name">{{recipeData(recipe.recipe_id).name}}</div>
          <div class="actions" v-if="deleteConfirmation !== recipe.id">
            <div class="danger-btn" @click="confirmDelete(recipe)">x</div>
          </div>
          <div class="confirm-delete flexy" v-if="deleteConfirmation === recipe.id">
            <span>Really remove recipe from meal?</span>
            <button @click="deleteIngredient(recipe)">Yes</button>
            <button @click="deleteConfirmation = null">No</button>
          </div>
        </div>
        <div class="recipe-ingredients">
          <div class="recipe-ingredient mini" v-for="ingredient in recipeData(recipe.recipe_id).ingredients" :key="ingredient.id">
            <div class="serving-amount" v-if="servingSize(ingredient.serving_size_id)">x{{ingredient.serving_amount}}</div>
            <div class="serving-size" v-if="servingSize(ingredient.serving_size_id)">{{ingredient.serving_size_id && servingSize(ingredient.serving_size_id) ? servingSize(ingredient.serving_size_id).name : "-"}}</div>
            <div class="name">{{ingredientData(ingredient).name}} <div v-if="missingFromPlan(ingredient)" class="error-pill">Missing from Plan!</div></div>
            <div>
              <div v-if="ingredient.is_dynamic" class="even">
                <div class="success-btn"></div><span> Dynamic</span>
              </div>
              <div v-if="!ingredient.is_dynamic" class="even">
                <div class="disabled-btn"></div><span> Fixed</span>
              </div>
            </div>
            <div class="amount">{{ingredient.amount_g}} g
              <b v-if="ingredient.serving_amount > 1">({{ingredient.amount_g * ingredient.serving_amount}} g)</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!recipes || recipes.length === 0" class="recipe-ingredient">
      No recipes
    </div>
    <div class="add-ingredient box" v-if="!passive">
      <h3>Add Recipe</h3>
      <div class="flexy">
        <div class="diagonal-wrap main">
          <v-select v-model="newRecipe" :options="allRecipes" :reduce="it => it.id" label="name" placeholder="- Select Recipe -" />
        </div>
        <div v-if="existing(newRecipe)" class="exists-error">This recipe already exists in this meal</div>
        <div v-if="!existing(newRecipe)" class="flexy">
          <div :class="'diagonal-wrap'+(newRecipe?'':' disabled')">
            <button @click="addRecipe">Add Recipe</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "MealRecipeForm",
  props: ['locale', 'locale_id','recipes','passive','planIngredients'],
  data() {
    return {
      deleteConfirmation: null,
      newRecipe : '',
      newDynamic : false,
      id : null
    }
  },

  computed : {
    ...mapGetters(['allRecipes','allServingSizes','allIngredients']),
  },

  methods : {
    ...mapActions(['getRecipes','getServingSizes',"getIngredients"]),

    recipeData(id) {
      return this.allRecipes.find(it => it.id === id) || {}
    },

    ingredientData(ingredient) {
      if (ingredient.ingredient) ingredient = ingredient.ingredient.id;
      else ingredient = ingredient.ingredient_id;
      return this.allIngredients.find(it => it.id === ingredient) || {}
    },

    servingSize(id) {
      return this.allServingSizes.find(it => it.id === id)
    },

    missingFromPlan(ingredient) {
      if (!this.planIngredients) return false
      const match = this.planIngredients.find(it => it.ingredient_id === ingredient.ingredient.id)
      if (!match) return true
    },

    confirmDelete(recipe) {
      this.deleteConfirmation = recipe.id;
    },

    deleteIngredient(ingredient) {
      console.log("Delete",ingredient)
      this.$emit('delete', ingredient.id)
      this.deleteConfirmation = null;
    },

    existing(id) {
      return !!(this.recipes.find(it => (it.id === id)))
    },

    addRecipe() {
      if (this.newRecipe) this.$emit('add', this.newRecipe)
      this.newRecipe = '';
    },

    async initData() {
      if (this.allIngredients.length <= 0)await this.getIngredients();
      if (this.allRecipes.length <= 0)await this.getRecipes();
      if (this.allServingSizes.length <= 0)await this.getServingSizes();
    }
  },

  mounted() {
    this.initData();
  },

  watch : {
    newRecipe() {
      this.newAmountG = this.newServingSize = ''
    }
  }
}
</script>
