import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        repGroups : []
    },
    getters : {
        allRepGroups: state => state.repGroups
    },
    actions : {
        async deleteRepGroup({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteRepGroup, id))
            return await commit('deleteRepGroup', id)
        },
        async getRepGroups({commit}) {
            const repGroups = await ApiRequest(ApiCall(Api.ListRepGroups));
            return await commit('setRepGroups', repGroups);
        },
        async updateRepGroup({commit}, [repGroup, id]) {
            await ApiRequest(ApiCall(Api.UpdateRepGroup, repGroup, id))
            return await commit('saveRepGroup', [repGroup, id])
        },
        async createRepGroup({commit}, repGroup) {
            const res = await ApiRequest(ApiCall(Api.CreateRepGroup, repGroup))
            repGroup.id = res.id
            return await commit('saveRepGroup', [repGroup, null])
        },

    },
    mutations : {
        setRepGroups: (state, repGroups) => state.repGroups = repGroups,
        saveRepGroup: (state, [repGroup, id]) => {
            if (id) {
                state.repGroups = state.repGroups.map(it => it.id === repGroup.id ? repGroup : it)
            }
            else {
                state.repGroups = [...state.repGroups, repGroup];
            }
            return state
        },
        deleteRepGroup(state, id) {
            state.repGroups = state.repGroups.filter(it => it.id !== id)
        },
    }
}
