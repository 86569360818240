<template>
  <div v-if="mealType" class="panel inline">
    <div class="panel-header">
      <p>Meal Type: <b>{{mealType.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="mealType" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ mealType.id?'Update Meal Type':'Create Meal Type' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import mealTypeFields from "@/lib/form-fields/mealType";
import AppForm from "@/components/ui/AppForm";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "MealType",
  components: { AppForm },
  data() {
    return {
      fields : mealTypeFields,
      mealType : null,
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMealTypes']),
  },

  methods: {
    ...mapActions(['getMealTypes','setLoading','setSuccess','updateMealType','createMealType']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.mealType.id) { //update
        await this.updateMealType([this.mealType, this.mealType.id])
      }
      else { // create new
        await this.createMealType(this.mealType);
        if (this.mealType.id) {
          await this.$router.push('/meal-type/'+this.mealType.id)
          await this.initData()
          this.mealType = {...this.mealType }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Meal Type saved");
    },

    onUpdate(values) {
      this.mealType = {...this.mealType, ...values};
    },

    async initData() {
      await actionIf([
        [!this.allMealTypes.length, this.getMealTypes],
      ])
      this.mealType = !isNaN(this.$route.params.id*1) ? this.allMealTypes.find(it => it.id === this.$route.params.id*1) :
          {name: "New Meal Type"}

    },
  },



}
</script>
