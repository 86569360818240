<template>
  <div class="panel inline">
    <div class="modal" v-if="planGoalForDeletion">
      <div class="modal-inner">
        <h3>Are you sure?</h3>
        <p>Do you really want to delete the goal <b>{{planGoalForDeletion.name}}</b>?</p>
        <div class="button-row even">
          <div class="diagonal-wrap">
            <button @click="confirmDeleteGoal">Delete Goal</button>
          </div>
          <button class="unwrap" @click="planGoalForDeletion = null">Cancel</button>
        </div>
      </div>
    </div>
    <div class="even packed">
      <div>
        <div class="panel-header">
          <p>Goals</p>
          <div class="search-box">
            <router-link to="/plan-goal/new" class="diagonal-wrap">
              <button>Add Goal</button>
            </router-link>
            <div class="diagonal-wrap">
              <input type="text" placeholder="Search Goals" v-model="search" />
            </div>
            <div class="close-btn" v-if="search" @click="() => this.search = ''"></div>
          </div>
        </div>
        <div class="panel-body">
          <div class="full-scroller in-panel">
            <app-list :data="filteredPlanGoals" :fields="fields" :on-click="gotoPlanGoal" @delete="askToDeletePlanGoal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import AppList from '../../ui/AppList';
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "PlanGoals",
  components: { AppList },

  data() {
    return {
      planGoalForDeletion: null,
      search : "",
      fields : [
        {label: "Name", prop: "name", primary: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ],
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.allPlanGoals.length, this.getPlanGoals],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanGoals']),
    filteredPlanGoals() { return this.search.length >= 1 ? this.allPlanGoals.filter(it => it.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) : this.allPlanGoals },
  },


  methods: {
    ...mapActions(['getPlanGoals','setLoading','deletePlanGoal']),
    gotoPlanGoal(group) {
      this.$router.push('/plan-goal/'+group.id)
    },
    askToDeletePlanGoal(group) {
      this.planGoalForDeletion = group;
    },
    confirmDeleteGoal() {
      if (this.planGoalForDeletion)
        this.deletePlanGoal(this.planGoalForDeletion.id)
      this.planGoalForDeletion = null
    },
  },
}
</script>
