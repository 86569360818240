<template>
  <div class="panel" v-if="nutrition">
    <div class="panel-body">
      <div class="panel-padding">
        <h2>Nutrients</h2>
        <div class="even">
          <div>
            <h3><span class="glow-text">Calories</span></h3>
            <p>{{nutrition.calories}}</p>
            <h4 class="bolder">Offsets</h4>
            <p><dfn>{{(nutrition.calories - calories).toFixed(2)}}</dfn> Calories, <dfn>{{(nutrition.calories/calories*100 - 100).toFixed(2)}}%</dfn></p>
          </div>
          <div>
            <h3><span class="glow-text">Protein</span></h3>
            <p>{{(nutrition.protein_g*4).toFixed(2)}} Calories</p>
            <p>{{(nutrition.protein_g*1).toFixed(2)}} G</p>
            <h4 class="bolder">Offsets</h4>
            <p><dfn>{{(nutrition.protein_g*4 - protein).toFixed(2)}}</dfn> Calories, <dfn>{{(nutrition.protein_g*4/protein*100 - 100).toFixed(2)}}%</dfn></p>
          </div>
          <div>
            <h3><span class="glow-text">Fat</span></h3>
            <p>{{(nutrition.fat_g*9).toFixed(2)}} Calories</p>
            <p>{{(nutrition.fat_g*1).toFixed(2)}} G</p>
            <h4 class="bolder">Offsets</h4>
            <p><dfn>{{(nutrition.fat_g*9 - fat).toFixed(2)}}</dfn> Calories, <dfn>{{(nutrition.fat_g*9/fat*100 - 100).toFixed(2)}}%</dfn></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: ['nutrition','calories','protein','fat'],
}
</script>
