<template>
  <div class="box">
    <h3>Buckets</h3>
    <div class="bolder flexy padded" v-if="designator"><p>A bucket sorting designator function exists</p></div>
    <div>
      <h4>
        <span class="glow-text">Data Size: <b>{{this.data && this.data.length}}</b></span>
      </h4>
    </div>
    <div v-for="(bucket, i) in buckets" @click="() => toggle(i)" :class="'form-group raw interactive flexy' + (bucket.inactive ? ' inactive':'')" :key="i">
      <div :class="bucket.inactive?'danger-btn':'success-btn'"></div>
      <b>{{bucket.name}}</b>
      <b>{{sizes && sizes[i] ? "("+sizes[i]+")" : ''}}</b>
    </div>
  </div>
</template>

<script>
import * as Single from "@/lib/Single";

export default {
  name: "BucketSwitcher",
  data() {
    return {
      buckets : null,
      data : null,
      designator : false,
      sizes : []
    }
  },

  methods : {
    toggle(index) {
      this.$set(this.buckets,index, {...this.buckets[index], inactive: !this.buckets[index].inactive})
      const tree = Single.get('tree');
      tree.options.buckets = [...this.buckets]
      tree.reset()
      this.$emit('reset')
    }
  },

  mounted() {
    setTimeout(() => {
      const tree = Single.get('tree');
      if (!tree) return;
      this.buckets = tree.options.buckets
      this.data = tree.options.data
      this.sizes = tree.sizes
      this.designator = !!tree.options.designator
    },2700)
  },
}
</script>

