<template>
  <div v-if="locale" class="panel inline">
    <div class="panel-header">
      <p>Locale: <b>{{locale.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="locale" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ locale.id?'Update Locale':'Create Locale' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import localeFields from "@/lib/form-fields/locale";
import AppForm from "@/components/ui/AppForm";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {

  name: "Locale",
  components: { AppForm },
  data() {
    return {
      fields : localeFields,
      locale : null,
      locales : null,
      settingLocale : null
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allLocales','allLocales']),
  },

  methods: {
    ...mapActions(['getLocales','setLoading','setSuccess','getLocales','updateLocale','createLocale']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.locale.id) { //update
        await this.updateLocale([this.locale, this.locale.id])
      }
      else { // create new
        await this.createLocale(this.locale);
        if (this.locale.id) {
          await this.$router.push('/locale/'+this.locale.id)
          await this.initData()
          this.locale = {...this.locale }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Locale saved");
    },

    onUpdate(values) {
      this.locale = {...this.locale, ...values};
    },

    async initData() {
      await actionIf([
        [!this.allLocales.length, this.getLocales],
      ])

      this.locale = !isNaN(this.$route.params.id*1) ? this.allLocales.find(it => it.id === this.$route.params.id*1) :
          {name: "New Locale"}

    },
  },


}
</script>
