<template>
  <div class="modal">
    <div class="modal-inner">
      <h3>Are you sure?</h3>
      <p>Do you really want to delete the {{itemType}} <b>{{itemName}}</b>?</p>
      <div class="button-row even">
        <div class="diagonal-wrap">
          <button @click="confirm">Delete {{itemType}}</button>
        </div>
        <button class="unwrap" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: ['itemType', 'itemName', 'confirm', 'cancel']
}
</script>
