import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        mealTypes : [],
        planMealTypes: []
    },
    getters : {
        allMealTypes: state => state.mealTypes,
        allPlanMealTypes: state => state.planMealTypes,
    },
    actions : {
        async deleteMealType({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteMealType, id))
            return await commit('deleteMealType', id)
        },
        async getMealTypes({commit}) {
            const mealTypes = await ApiRequest(ApiCall(Api.ListMealTypes));
            return await commit('setMealTypes', mealTypes);
        },
        async updateMealType({commit}, [mealType, id]) {
            await ApiRequest(ApiCall(Api.UpdateMealType, mealType, id))
            return await commit('saveMealType', [mealType, id])
        },
        async createMealType({commit}, mealType) {
            const res = await ApiRequest(ApiCall(Api.CreateMealType, mealType))
            mealType.id = res.insertId
            return await commit('saveMealType', [mealType, null])
        },

        async getPlanMealTypes({commit}) {
            const mealTypes = await ApiRequest(ApiCall(Api.ListPlanMealTypes))
            return await commit('setPlanMealTypes', mealTypes)
        },

        async setPlanMealTypes({commit}, meal_types) {
            await ApiRequest(ApiCall(Api.SetPlanMealTypes, {meal_types}))
            return await commit('setPlanMealTypes', meal_types);
        },

    },
    mutations : {
        setPlanMealTypes: (state, mealTypes) => state.planMealTypes = mealTypes,
        setMealTypes: (state, mealTypes) => state.mealTypes = mealTypes,
        saveMealType: (state, [mealType, id]) => {
            if (id) {
                state.mealTypes = state.mealTypes.map(it => it.id === mealType.id ? mealType : it)
            }
            else {
                state.mealTypes = [...state.mealTypes, mealType];
            }
            return state
        },
        deleteMealType(state, id) {
            state.mealTypes = state.mealTypes.filter(it => it.id !== id)
        },
    }
}
