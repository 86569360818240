import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        ingredients : [],
        planIngredients: [],
        icons: [],
    },
    getters : {
        allIngredients: state => state.ingredients,
        allPlanIngredients: state => state.planIngredients,
        allPlanIngredientIcons : state => state.icons,
    },
    actions : {
        async getIngredients({commit}) {
            const ingredients = await ApiRequest(ApiCall(Api.ListIngredients));
            return await commit('setIngredients', ingredients);
        },
        async updateIngredient({commit}, [ingredient, id]) {
            await ApiRequest(ApiCall(Api.UpdateIngredient, ingredient, id))
            return await commit('saveIngredient', [ingredient, id])
        },
        async createIngredient({commit}, ingredient) {
            const res = await ApiRequest(ApiCall(Api.CreateIngredient, ingredient))
            ingredient.id = res.insertId
            return await commit('saveIngredient', [ingredient, null])
        },
        async deleteIngredient({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteIngredient, id))
            return await commit('deleteIngredient', id)
        },

        async getPlanIngredients({commit}) {
            const ingredients = await ApiRequest(ApiCall(Api.ListPlanIngredients));
            return await commit('setPlanIngredients', ingredients);
        },
        async createPlanIngredient({commit}, ingredient) {
            const r = await ApiRequest(ApiCall(Api.CreatePlanIngredient, ingredient))
            ingredient.id = r.id;
            return await commit('createPlanIngredient', ingredient);
        },
        async updatePlanIngredient({commit}, [ingredient, id]) {
            await ApiRequest(ApiCall(Api.UpdatePlanIngredient, ingredient, id))
            return await commit('savePlanIngredient', [ingredient, id])
        },
        async deletePlanIngredient({commit}, id) {
            await ApiRequest(ApiCall(Api.DeletePlanIngredient, id))
            return await commit('deletePlanIngredient', id);
        },

        async getPlanIngredientIcons({commit}) {
            const icons = await ApiRequest(ApiCall(Api.ListPlanIngredientIcons))
            return await commit('setPlanIngredientIcons', icons);
        },
        async createPlanIngredientIcon({commit}, ingredient) {
            const r = await ApiRequest(ApiCall(Api.CreatePlanIngredientIcon, ingredient))
            ingredient.id = r.id;
            return await commit('createPlanIngredientIcon', ingredient);
        },
        async updatePlanIngredientIcon({commit}, [ingredient, id]) {
            await ApiRequest(ApiCall(Api.UpdatePlanIngredientIcon, ingredient, id))
            return await commit('savePlanIngredientIcon', [ingredient, id])
        },
        async deletePlanIngredientIcon({commit}, id) {
            await ApiRequest(ApiCall(Api.DeletePlanIngredientIcon, id))
            return await commit('deletePlanIngredientIcon', id);
        },


    },
    mutations : {
        setIngredients: (state, ingredients) => state.ingredients = ingredients,
        setPlanIngredients: (state, ingredients) => state.planIngredients = ingredients,
        setPlanIngredientIcons: (state, icons) => state.icons = icons,
        saveIngredient: (state, [ingredient, id]) => {
            if (id) {
                state.ingredients = state.ingredients.map(it => it.id === ingredient.id ? ingredient : it)
            }
            else {
                state.ingredients = [...state.ingredients, ingredient];
            }
            return state
        },
        deleteIngredient(state, id) {
            state.ingredients = state.ingredients.filter(it => it.id !== id)
        },

        createPlanIngredient(state, ingredient) {
            state.planIngredients.push(ingredient)
        },
        deletePlanIngredient(state, id) {
            state.planIngredients = state.planIngredients.filter(it => it.id !== id)
        },
        savePlanIngredient : (state,[ingredient,id]) => {
            if (id) {
                state.planIngredients = state.planIngredients.map(it => it.id === ingredient.id ? ingredient : it)
            }
            else {
                state.planIngredients = [...state.planIngredients, ingredient]
            }
        },

        createPlanIngredientIcon(state, icon) {
            if (state.icons) state.icons.push(icon)
        },
        deletePlanIngredientIcon(state, id) {
            state.icons = state.icons.filter(it => it.id !== id)
        },
        savePlanIngredientIcon : (state,[icon,id]) => {
            if (id) {
                state.icons = state.icons.map(it => it.id === icon.id ? icon : it)
            }
            else {
                state.icons = [...state.icons, icon]
            }
        }
    }
}
