<template>
    <div class="panel inline">
      <ConfirmModal v-if="userForDeletion" item-type="User" :item-name="userForDeletion.username" :confirm="confirmDeleteUser" :cancel="cancelDeleteUser" />
      <ListPanel title="Users" :fields="fields" :items="allUsers" :on-add="addNewUser" :on-delete="askToDeleteUser" :on-row="goto" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "Users",
  components: {ListPanel, ConfirmModal },

  data() {
    return {
      userForDeletion: null,
      search : "",
      fields : [
        {label: "Username", prop: "username", primary: true, sortable: true},
        {label: "Role", prop: "role_id", sortable: true, format: (id) => { const role = this.allUserRoles.find(it => it.id === id);  return role ? role.name : '-'  } },
        {label: "Locale", prop: "locale_id", sortable: true, format: (id) => { const locale = this.allLocales.find(it => it.id === id);  return locale ? locale.description : '-'  } },
        {label: "Plan", prop: "plan_id", sortable: true, format: (id) => { const plan = this.allPlans.find(it => it.id === id);  return plan ? plan.name : '-'  } },
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allUsers', 'allUserRoles', 'allLocales', 'allPlans']),
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
        [!this.allUsers.length, this.getUsers],
        [!this.allUserRoles.length, this.getUserRoles],
        [!this.allLocales.length, this.getLocales],
        [!this.allPlans.length, this.getPlans],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getUsers','getUserRoles','setLoading','deleteUser', 'getLocales', 'getPlans']),
    goto(user) {
      this.$router.push('/user/'+user.id)
    },
    addNewUser() {
      this.$router.push('/user/new')
    },
    askToDeleteUser(user) {
      this.userForDeletion = user;
    },
    cancelDeleteUser() { this.userForDeletion = null },
    confirmDeleteUser() {
      this.deleteUser(this.userForDeletion.id)
      this.userForDeletion = null
    }
  },
}
</script>
