<template>
  <div v-if="ingredient" class="panel inline">
    <div class="panel-header">
      <p>Ingredient: <b>{{ingredient.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <AppForm :fields="fields" :data="ingredient" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="box-header"><p>USDA Search</p></div>
              <p>Search and import USDA Survey nutrients data</p>
              <UsdaForm @select="onUsdaValues" :id="usdaId" :set-sizes="onSetSizes" :ingredient-name="ingredient&&ingredient.name" />
            </div>
            <div class="box" v-if="ingredient.id">
              <div class="box-header"><p>Tags</p></div>
              <TagList :all-tags="allTags" tag-key="tag_id" :tags="ingredient.tags" prop="tag" @remove-tag="onRemoveTag" />
              <AddTagForm :tags="allTags" @add="onAddTag" />
            </div>
            <div class="box" v-if="ingredient.id">
              <div class="box-header"><p>Serving Sizes</p></div>
              <TagList :all-tags="allServingSizes" tag-key="serving_size_id"
                        :tags="ingredient.serving_sizes" prop="name" secondary="amount_g" secondary-suffix="g" @remove-tag="onRemoveSize" />
              <ServingSizeForm :serving-sizes="ingredientServingSizes" :existing-sizes="ingredient.serving_sizes" @update="onServingSizes" @add="onAddSize" />
            </div>
            <div class="box" v-if="ingredient.id">
              <div class="box-header"><p>Markets</p></div>
              <TagList :all-tags="allMarkets" tag-key="market_id" :tags="ingredient.markets" prop="name" @remove-tag="onRemoveMarket" />
              <AddTagForm :tags="allMarkets" label="name" placeholder="Select a Market" @add="onAddMarket" />
            </div>
            <div class="box" v-if="ingredient.id">
              <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" :on-submit="setLocale" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ ingredient.id?'Update Ingredient':'Create Ingredient' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ingredientFields from "@/lib/form-fields/ingredient";
import AppForm from "@/components/ui/AppForm";
import UsdaForm from "@/components/forms/UsdaForm";
import TagList from "@/components/ui/TagList";
import AddTagForm from "@/components/forms/AddTagForm";
import ServingSizeForm from "@/components/forms/AddServingSizeForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import LocaleForm from "@/components/forms/LocaleForm";
import {actionIf, delayedIfLoading} from "@/lib/Async";

const localeFields = [
  {name: "name", label:"Name", type:"text"},
  {name: "instructions", label:"Instructions", type:"textarea"},
]
export default {

  name: "Ingredient",
  components: {LocaleForm, AppForm, UsdaForm, TagList, AddTagForm, ServingSizeForm },

  data() {
    return {
      fields : ingredientFields,
      localeFields,
      ingredient : null,
      locales : null,
      usdaSizes : null,
      usdaId : null,
    }
  },
  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allIngredients', 'allTags','allServingSizes','allMarkets','allLocales']),
    ingredientServingSizes() {
      return this.allServingSizes
    }
  },

  methods: {
    ...mapActions(['getIngredients','getTags','setLoading','setSuccess','getServingSizes','getMarkets','getLocales','updateIngredient','createIngredient','createServingSize']),

    async onSubmit() {
      await this.setLoading(true);

      //update
      if (this.ingredient.id) {
        await this.updateIngredient([this.ingredient, this.ingredient.id])
      }

      // create new
      else {
        await this.createIngredient(this.ingredient); // this.ingredient.id is created here
        if (this.ingredient.id) {
          await this.$router.push('/ingredient/'+this.ingredient.id)
          await this.initData()
          this.ingredient = {...this.ingredient }
        }
      }

      if (this.usdaSizes) {
        await this.createServingSizes(this.ingredient.id, this.usdaSizes);
        this.usdaSizes = this.usdaId = null;
      }
      await this.setLoading(false);
      await this.setSuccess("Ingredient saved");
    },

    async createServingSizes(ingredient_id, sizes) {
      let servingSize;
      for (let size of sizes) {
        size.name = this.ingredient.name + ' - '+size.name;
        servingSize = await this.createServingSize(size)
        this.ingredient.serving_sizes.push({amount_g: servingSize.amount_g, serving_size_id: servingSize.id, ingredient_id})
      }
      await this.updateIngredient([this.ingredient, this.ingredient.id])
    },

    onUpdate(values) {
      this.ingredient = {...this.ingredient, ...values};
    },

    async onUsdaValues(ingredient) {
      this.ingredient = {...this.ingredient, nutrition: {...this.ingredient.nutrition, ...ingredient.nutrients }}
      this.usdaId = ingredient.id;
    },

    onTags(tags) {
      this.ingredient.tags = tags;
    },

    onSetSizes(sizes) {
      this.usdaSizes = sizes;
    },

    onServingSizes(servingSizes) {
      this.ingredient.serving_sizes = servingSizes;
    },

    onAddTag(tagId) {
      const tag = this.allTags.find(it => it.id === tagId)
      if (tag) {
        this.ingredient.tags.push({tag_id : tag.id, ingredient_id: this.ingredient.id || null})
      }
    },

    onAddMarket(marketId) {
      const market = this.allMarkets.find(it => it.id === marketId)
      if (market) {
        this.ingredient.markets.push({market_id : marketId, ingredient_id: this.ingredient.id || null})
      }
    },

    onAddSize(sizeId, amountG) {
      const size = this.allServingSizes.find(it => it.id === sizeId)
      size.amount_g = amountG
      if (size) {
        this.ingredient.serving_sizes.push({serving_size_id: sizeId, amount_g: amountG, ingredient_id: this.ingredient.id || null})
      }
    },

    onRemoveTag(id) {
      this.ingredient.tags = this.ingredient.tags.filter(it => it.id !== id)
    },

    onRemoveMarket(id) {
      this.ingredient.markets = this.ingredient.markets.filter(it => it.id !== id)
    },

    onRemoveSize(id) {
      this.ingredient.serving_sizes = this.ingredient.serving_sizes.filter(it => it.id !== id)
    },

    async initData() {
      await actionIf([
          [!this.allIngredients.length, this.getIngredients],
          [!this.allTags.length, this.getTags],
          [!this.allServingSizes.length, this.getServingSizes],
          [!this.allMarkets.length, this.getMarkets],
          [!this.allLocales.length, this.getLocales],
      ])

      this.ingredient = !isNaN(this.$route.params.id*1) ? this.allIngredients.find(it => it.id === this.$route.params.id*1) :
          {name: "", tags:[], serving_sizes:[], markets: []}

      if (this.ingredient.id) await this.getIngredientLocales()
    },

    async getIngredientLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetIngredientLocales, this.ingredient.id))
    },

    async createIngredientLocale(ingredient_id, locale_id, name, instructions) {
      return await ApiRequest(ApiCall(Api.CreateIngredientLocale, { locale_id, name, instructions },  ingredient_id))
    },
    async updateIngredientLocale(ingredient_id, locale_id, name, instructions) {
      return await ApiRequest(ApiCall(Api.UpdateIngredientLocale, {name, instructions},  ingredient_id+'/'+locale_id))
    },

    async setLocale({name, instructions, id = null}, locale_id) {
      if (id) {
        await this.updateIngredientLocale(this.ingredient.id, locale_id, name, instructions)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, name, instructions} : it)
      }
      else {
        const res = await this.createIngredientLocale(this.ingredient.id, locale_id, name, instructions)
        this.locales.push({id: res.insertId, locale_id, name, instructions})
      }
    }
  },
}
</script>
