<template>
  <div class="settings-panel">
    <div class="panel-header">
      <p>Meal Styles</p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <InfoBox title="Assign meal styles to your plan.">
            <p>We offer various <b>Meal Styles</b> from which the menu is built. Here you can choose which
              styles should become available as either <b>Main Styles</b> or <b>Replacement Styles</b>.</p>
            <h3>Main Styles</h3>
            <p>Meals with chosen main styles will appear under <router-link to="/plan-meals">My Meals</router-link>,
              allowing you to add them to your plan.<br />
              Meals with <b>Main Styles</b> will appear in regular menus, created by the menu builder.</p>
            <h3>Replacement Styles</h3>
            <p>Meals with chosen replacement styles will appear under <router-link to="/plan-meals">Meal Replacements</router-link>,
              allowing you to add them to your plan.<br />
              Meals with <b>Replacement Styles</b> will <b>not</b> appear in regular menus, but they can be
              chosen as replacement meals if your plan allows it.</p>
          </InfoBox>

          <div class="even">
            <div class="box">
              <h3 class="bolder">Main Styles</h3>
              <ItemList :all-items="allMealStyles" :items="mainStyles" prop="name" item-key="id" @remove-item="removeMealStyle" />
              <ItemAddForm :items="allMealStyles" placeholder="Add Main Style" type="Main Style" label-prop="name" value-prop="id"
                           @add="value => addMealStyle(value, true)"/>
            </div>
            <div class="box">
              <h3 class="bolder">Replacement Styles</h3>

              <ItemList :all-items="allMealStyles" :items="replacementStyles" prop="name" item-key="id" @remove-item="removeMealStyle" />
              <ItemAddForm :items="allMealStyles" placeholder="Add Replacement Style" type="Replacement Style" label-prop="name" value-prop="id"
                           @add="value => addMealStyle(value, false)" />
            </div>
          </div>

          <InfoBox title="Set Meal Replacement Offsets">
            Define the minimum and maximum allowed nutrient offset from an existing menu meal. If a replacement meal for the
            same meal time is within these bounds, it will be offered.<br />
            <b>Note:</b> the ranges are relative offsets. For example, setting min to 0.95 and max to 1.05 will allow a 5% offset to each side.
          </InfoBox>
          <div class="even">
            <div class="box">
              <h3><span class="glow-text">Calories</span></h3>
              <div class="form-group">
                <div class="form-group-content even">
                  <div class="flexy"><span>Min</span><input type="number" v-model="mealRepOffsets.calories.min" /></div>
                  <div class="flexy"><span>Max</span><input type="number" v-model="mealRepOffsets.calories.max" /></div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form-group-content">
                <h3><span class="glow-text">Protein</span></h3>
                <div class="form-group">
                  <div class="form-group-content even">
                    <div class="flexy"><span>Min</span><input type="number" v-model="mealRepOffsets.protein.min" /></div>
                    <div class="flexy"><span>Max</span><input type="number" v-model="mealRepOffsets.protein.max" /></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form-group-content">
                <h3><span class="glow-text">Fat</span></h3>
                <div class="form-group">
                  <div class="form-group-content even">
                    <div class="flexy"><span>Min</span><input type="number" v-model="mealRepOffsets.fat.min" /></div>
                    <div class="flexy"><span>Max</span><input type="number" v-model="mealRepOffsets.fat.max" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="side">
          <div class="box">
            <div class="diagonal-wrap">
              <button class="large-button" @click="onSubmit">Save Meal Styles</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InfoBox from "@/components/ui/InfoBox";
import ItemAddForm from "@/components/ui/ItemAddForm";
import ItemList from "@/components/ui/ItemList";

export default {
  name: "PlanMealStyles",
  components: {ItemList, ItemAddForm, InfoBox},
  computed: {
    ...mapGetters(['allPlanMeals', 'allPlanIngredients', 'allPlanMealStyles', 'allMealStyles','myPlan']),
    addedMealTypes() { return this.allMealStyles ? this.mealStyles.map(it => this.allMealStyles.find(mt => mt.id === (it.meal_style_id ? it.meal_style_id:it))) : null },
    mainStyles() {return this.allPlanMealStyles ? this.mealStyles.filter(it => it.is_main === true) : []},
    replacementStyles() {return this.allPlanMealStyles ? this.mealStyles.filter(it => it.is_main === false) : []}
  },

  data() {
    return {
      mealStyles: [],
      dragOrder:[],
      mealRepOffsets : {
        calories: {min: 0.95, max: 1.05},
        protein: {min: 0.9, max: 1.1},
        fat: {min: 0.75, max: 1.5},
      }
    }
  },

  async mounted() {
    if (!this.myPlan) await this.getMyPlan();
    if (this.allPlanMeals.length <= 0) await this.getPlanMeals();
    if (this.allMealStyles.length <= 0) await this.getMealStyles();
    if (this.allPlanMealStyles.length <= 0) await this.getPlanMealStyles();
    this.mealStyles = [...this.allPlanMealStyles]

    if (this.myPlan.meal_rep_offsets && this.myPlan.meal_rep_offsets.calories) this.mealRepOffsets = this.myPlan.meal_rep_offsets
  },

  methods: {
    ...mapActions(['getPlanMeals', 'getPlanIngredients', 'getPlanMealStyles', 'getMealStyles', 'setSuccess', 'setPlanMealStyles','getMyPlan','setPlanmealRepOffsets']),

    addMealStyle(id, is_main = false) {
        if (!this.mealStyles.find(it => it.id === id)) {
          this.mealStyles.push({id, is_main});
        }
    },

    removeMealStyle(id) {
      this.mealStyles = this.mealStyles.filter(it => it.id !== id)
    },

    async onSubmit() {
      console.log(this.myPlan)
      await this.setPlanMealStyles(this.mealStyles)
      await this.setPlanmealRepOffsets([this.myPlan.id, this.mealRepOffsets])
      await this.setSuccess("Meal Styles saved");
    }
  },
}
</script>
