<template>
  <ul class="icon-list">
    <li v-for="icon in icons" :key="icon.id" :style="{backgroundColor: icon.color, backgroundImage: img(icon)}" @click="select(icon)">

    </li>
  </ul>
</template>

<script>
export default {
  name: "IconList",
  props : ['icons','passive'],
  methods : {
    select(icon) {
      if (!this.passive) this.$emit('select', icon)
    },
    img(icon) {
      return `url(${icon.image})`
    }
  }
}
</script>
