<template>
  <ul class="side-menu">
    <li v-for="(item, index) in items" :key="index" :class="item.path === path?'active':''"><a @click="onClick(item)">{{item.label}}</a></li>
  </ul>
</template>

<script>
export default {
  name: "SideMenu",
  props:['items','path'],
  methods : {
    onClick(item) {
      this.$emit('change', item);
    }
  }
}
</script>
