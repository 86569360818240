<template>
  <div class="box">
    <div v-if="!raw">
      <h2>Result Sorting</h2>
      <p>set the sorting power of each property. The defaults can be saved under plan settings.</p>
    </div>
    <div class="even">
      <div>
        <h3>Calories</h3>
        <vue-slider v-model="powerCal" @change="(v) => onInputChange('powerCal', v)" :min="0" :max="10" tooltip="always" tooltip-placement="bottom" />
      </div>
      <div>
        <h3>Protein</h3>
        <vue-slider v-model="powerProtein" @change="(v) => onInputChange('powerProtein', v)" :min="0" :max="10" tooltip="always" tooltip-placement="bottom" />
      </div>
      <div>
        <h3>Fat</h3>
        <vue-slider v-model="powerFat" @change="(v) => onInputChange('powerFat', v)" :min="0" :max="10" tooltip="always" tooltip-placement="bottom" />
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'

export default {
    props: ['onChange', 'raw','data'],
    components : { VueSlider },
    data() {
        return {
            powerCal : 1,
            powerProtein : 1,
            powerFat : 1
        }
    },

    mounted() {
        if (this.data) {
          if (!isNaN(this.data.powerCal)) this.powerCal = this.data.powerCal;
          if (!isNaN(this.data.powerProtein)) this.powerProtein = this.data.powerProtein;
          if (!isNaN(this.data.powerFat)) this.powerFat = this.data.powerFat;
        }
    },

    methods: {
        onInputChange(prop, value) {
            this.onChange(prop, value);
        }
    },

    watch : {
      data() {
        if (!isNaN(this.data.powerCal)) this.powerCal = this.data.powerCal;
        if (!isNaN(this.data.powerProtein)) this.powerProtein = this.data.powerProtein;
        if (!isNaN(this.data.powerFat)) this.powerFat = this.data.powerFat;
      }
    },
}
</script>
