<template>
  <div class="tag-form">
    <div class="form-input flexy">
      <h3>Add </h3>
      <div class="diagonal-wrap main">
        <v-select v-model="newTag" :options="tags" :label="label?label:'tag'" :reduce="it => it.id" :placeholder="placeholder?placeholder:'Select a Tag'" />
      </div>
      <div :class="'diagonal-wrap square-wrap' + (newTag ? '':' disabled')">
        <button class="square" @click="addTag"><span class="icon-text">+</span></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddTagForm",
  props: ['tags','label', 'placeholder'],
  data() {
    return {
      newTag : ''
    }
  },
  methods : {
    addTag() {
      if (this.newTag) {
        this.$emit('add', this.newTag)
        this.newTag = ''
      }
    }
  }
}
</script>
