<template>
  <div class="icon-picker">
    <div class="current" :style="{backgroundColor: currentColor, backgroundImage: img(current)}" @click="selecting = !selecting"></div>
    <IconList v-if="selecting && !passive" :icons="icons" @select="select" />
  </div>
</template>

<script>
import IconList from "@/components/ui/IconList";
export default {
  name: "IconPicker",
  components: {IconList},
  props : ['current', 'icons','passive'],
  data() {
    return {
      selecting : false,
    }
  },

  computed : {
    currentColor() {
      const icon = (this.current && !isNaN(this.current)) ? this.icons.find(it => it.id === this.current) : null

      return icon ?
          icon.color :
          'transparent'
    }
  },

  methods : {
    select(icon) {
      if (!this.passive) this.$emit('select', icon)
      this.selecting = false;
    },
    img(icon) {
      if (icon && !isNaN(icon*1)) {
        icon = this.icons.find(it => it.id === icon)
      }
      if(!icon) return '';
      return `url(${icon.image? icon.image : icon})`
    }
  },
}
</script>
