<template>
  <ul class="listing">
    <li v-for="(item, index) in items" :key="item.id">
      <div class="main">
        <span v-if="open !== index">{{item.name}}</span>
        <div v-if="open === index">
          <component v-if="formComponent" :is="formComponent" :cancel="cancel" :data="item" :meta="meta" :submit="submit" />
        </div>
      </div>
      <div class="info-btn" v-if="open !== index" @click="select(index)">+</div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ListAddForm",
  props: ['items', 'formComponent','submit','meta'],
  data() {
    return {
      open: -1
    }
  },
  methods : {
    select(index) {
      this.open = index
    },
    cancel() {
      this.open = null
    }
  }
}
</script>
