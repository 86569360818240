import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        tags : []
    },
    getters : {
        allTags: state => state.tags
    },
    actions : {
        async deleteTag({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteTag, id))
            return await commit('deleteTag', id)
        },
        async getTags({commit}) {
            const tags = await ApiRequest(ApiCall(Api.ListTags));
            return await commit('setTags', tags);
        },
        async updateTag({commit}, [tag, id]) {
            await ApiRequest(ApiCall(Api.UpdateTag, tag, id))
            return await commit('saveTag', [tag, id])
        },
        async createTag({commit}, tag) {
            const res = await ApiRequest(ApiCall(Api.CreateTag, tag))
            tag.id = res.insertId
            return await commit('saveTag', [tag, null])
        },

    },
    mutations : {
        setTags: (state, tags) => state.tags = tags,
        saveTag: (state, [tag, id]) => {
            if (id) {
                state.tags = state.tags.map(it => it.id === tag.id ? tag : it)
            }
            else {
                state.tags = [...state.tags, tag];
            }
            return state
        },
        deleteTag(state, id) {
            state.tags = state.tags.filter(it => it.id !== id)
        },
    }
}
