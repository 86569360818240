<template>
  <div :class="'nutrition-counter'+(compact?' compact':'')">
    <h3>{{ label ? label : 'Nutrients' }}</h3>
    <ul class="nutrients">
      <li v-for="(nut,name) in nutrients" :key="name">
        <div class="label">{{name}}</div>
        <div class="value">{{nut.toFixed(2)}}</div>
      </li>
    </ul>
    <div v-if="!compact">
      <h3>Summary</h3>
      <ul class="nutrients">
        <li>
          <div class="label">Protein</div>
          <div class="value">{{(nutrients.protein_g*4 / nutrients.calories * 100).toFixed(2)}}%</div>
        </li>
        <li>
          <div class="label">Fat</div>
          <div class="value">{{(nutrients.fat_g*9 / nutrients.calories * 100).toFixed(2)}}%</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
name: "NutritionCounter",
  props: ['ingredients','compact','label'],

  data() {
    return {
      'fields': ['calories','protein_g','fat_g','carbs_g','sugars_g','fiber_g','saturated_fat_g','trans_fat_g','cholesterol_mg']
    }
  },

  computed : {
    ...mapGetters(['allIngredients']),
    nutrients() {
      const n = {};
      for (let ing of this.ingredients) {
        if (!ing.ingredient || !ing.ingredient.id) {
          if (ing.ingredient_id && this.allIngredients) ing.ingredient = this.allIngredients.find(it => it.id === ing.ingredient_id)
          if (!ing.ingredient) continue;
        }
        for (let nut in ing.ingredient.nutrition) {
          if (this.fields.indexOf(nut) < 0) continue;
          if (isNaN(ing.ingredient.nutrition[nut]*1)) continue;
          if (!n[nut]) n[nut] = 0;
          n[nut] += ing.ingredient.nutrition[nut] / 100 * ing.amount_g * ing.serving_amount;
        }
      }
      return n;
    }
  }
}
</script>
