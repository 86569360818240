<template>
  <div class="tag-form">
    <div class="form-padded">
      <label>
      <h3>Nutrition Goals</h3>
      </label>
      <div class="form-input" v-for="(goal, i) in nutritionGoals" v-bind:key="i">
        <div class="flexy">
          <div class="flex-1">Nutrient: <b class="bold">{{nutrientLabel(goal.nutrient)}}</b></div>
          <div class="flex-1">Target Value: <b class="bold">{{goal.targetValue}}</b></div>
          <div class="flex-1">Type: <b class="bold">{{typeLabel(goal.type)}}</b></div>
          <div class="flex-1"><span v-if="goal.activityLevel">Up to Activity Level: <b class="bold">{{goal.activityLevel}}</b></span></div>
          <div class="diagonal-wrap">
            <button class="square" @click="$emit('remove', i)"><span class="icon-text">x</span></button>
          </div>
        </div>
      </div>
    </div>
      <div class="form-field">
        <label><h4>Add Nutrition Goal</h4></label>
        <div class="form-input">
          <v-select v-model="nutrient" :options="nutrients" label="name" :reduce="it => it.id" placeholder="Select Nutrient" />
          <input v-model="targetValue" type="number" placeholder="Target Value" />
          <v-select v-model="type" :options="types" label="name" :reduce="it => it.id" placeholder="Select Type" />
          <input v-model="activityLevel" type="number" placeholder="Up to activity level" />
          <div class="diagonal-wrap">
            <button class="square" @click="addNutritionGoal"><span class="icon-text">+</span></button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "NutritionGoalForm",
  props: ['nutritionGoals'],
  data() {
    return {
      nutrient : '',
      targetValue : '',
      activityLevel : '',
      type : ''
    }
  },
  computed : {
    nutrients() {
      return [
        {id: 'protein', name: 'Protein'},
        {id: 'fat', name: 'Fat'},
        {id: 'carbs', name: 'Carbs'}
      ]
    },
    types() {
      return [
        {id: 'g', name: 'Grams per body weight in kg'},
        {id: '%', name: 'Percentage'}
      ]
    },
  },
  methods : {
    addNutritionGoal() {
      if (this.nutrient && this.targetValue && this.type) {
        this.$emit('add', {nutrient: this.nutrient, targetValue: this.targetValue, type: this.type, activityLevel: this.activityLevel})
        this.nutrient = ''
        this.value = ''
        this.type = ''
        this.activityLevel = ''
      }
    },
    typeLabel(type) {
      return this.types.find(it => it.id === type).name
    },
    nutrientLabel(nutrient) {
      return this.nutrients.find(it => it.id === nutrient).name
    }
  }
}
</script>
