<template>
    <div class="panel inline">
      <ConfirmModal v-if="recipeForDeletion" item-type="Recipe" :item-name="recipeForDeletion.name" :confirm="confirmDeleteRecipe" :cancel="cancelDeleteRecipe" />
      <ListPanel title="Recipes" :fields="fields" :items="allRecipes" :on-add="addNewRecipe" :on-delete="askToDeleteRecipe" :on-row="goto" export-file="recipes.csv" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {mealNutrition} from "@/lib/NutritionUtils";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "Recipes",
  components: {ListPanel, ConfirmModal },
  data() {
    return {
      recipeForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: 'str', width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "", prop: "id", target:"errors", primary: true, sortable: true, pill: 'error', format: (id) => this.recipeErrors(id) },
        {label: "Special", prop: "is_special", width: "10%", boolean: true, sortable: true},
        {label: "Calories", prop: "id", target:"calories_g", width:"70px", sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'calories')},
        {label: "Protein (g)", prop: "id", target:"protein_g", width:"100px", sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'protein_g')},
        {label: "Fat (g)", prop: "id", target:"fat", width:"70px", sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'fat_g')},
        {label: "% Protein", prop: "id", target:"protein_percent", width:"80px", sortable: true, format : (meal_id) => this.rowPercentage(meal_id, 'protein_g', 4)},
        {label: "% Fat", prop: "id", target:"fat_percent", width:"60px", sortable: true, format : (meal_id) => this.rowPercentage(meal_id, 'fat_g', 9)},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.allRecipes.length, this.getRecipes],
          [!this.allIngredients.length, this.getIngredients],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allRecipes','allIngredients']),
  },

  methods: {
    ...mapActions(['getRecipes','setLoading','deleteRecipe','getIngredients']),
    goto(recipe) {
      this.$router.push('/recipe/'+recipe.id)
    },

    rowNutrition(meal_id, prop) {
      if (this.allRecipes) {
        const target = this.allRecipes.find(it => it.id === meal_id);
        if (target) {
          const nutrition = mealNutrition(this.allRecipes.find(it => it.id === meal_id), false, this.allIngredients, true);
          return nutrition[prop];
        }
      }
    },
    rowPercentage(meal_id, prop, factor) {
      let res;
      if (this.allRecipes) {
        const target = this.allRecipes.find(it => it.id === meal_id);
        if (target) {
          const nutrition = mealNutrition(this.allRecipes.find(it => it.id === meal_id), false, this.allIngredients, true);
          res = (nutrition[prop] * factor / nutrition.calories * 100).toFixed(2);
        }

        return res;
      }
    },

    recipeErrors(id) {
      const target = this.allRecipes.find(it => it.id === id);
      for (let ing of target.ingredients) {
        if (!ing.ingredient) return "Missing Ingredient"
      }
      return ''
    },

    addNewRecipe() {
      this.$router.push('/recipe/new')
    },
    askToDeleteRecipe(recipe) {
      this.recipeForDeletion = recipe;
    },
    cancelDeleteRecipe() { this.recipeForDeletion = null },
    confirmDeleteRecipe() {
      this.deleteRecipe(this.recipeForDeletion.id)
      this.recipeForDeletion = null
    }
  },
}
</script>
