<template>
  <div class="settings-panel">
    <div class="panel-header">
      <p>Menu Rules</p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <InfoBox title="Configuring your plan's menu rules">
            <p>The menu is built by filtering all possible meal combinations through a set of rules you can define here.</p>
            <p>Rules can be turned on/off in the <router-link to="/plan-menu">Menu Builder</router-link> to check their efficiency.</p>
          </InfoBox>

          <Draggable v-model="dragOrder" class="box" v-if="rules.length > 0" handle=".handle-icon" @end="onDragEnd">
            <RuleForm v-for="(rule, index) of rules" :key="index" :rule="rule" :inputs="myPlan?myPlan.inputs:[]" :sources="sources" :buckets="addedMealTypes" :on-remove="d => onRemove(index)" :on-change-data="d => onChangeData(index, d)" :on-change-name="d => onChangeName(index, d)" />
          </Draggable>

          <div class="form-group">
            <div class="form-group-content">
              <h2><span class="glow-text">Add New Rule</span></h2>
              <div class="flexy">
                <input type="text" v-model="newName" placeholder="Rule Name" />
                <v-select v-model="newType" :options="['boolean','sum-range','bucket-compare']" placeholder="- Select Rule Type -" :clearable="false" />
                <v-select v-model="newSource" :options="selectSources" label="name" :reduce="it => it.slug" placeholder="- Select Rule Source -" :clearable="false" />
                <div :class="'diagonal-wrap' + (newName && newType && newSource ? '':' disabled')"><button @click="onAdd">Add Rule</button></div>
              </div>
              <InfoBox title="Setting a new rule">
                <p>The <span class="bold">rule type</span> field defines how data is compared. <span class="bold">boolean</span> tests every single meal in the combination, <span class="bold">sum-range</span> filters entire menus by valid ranges, <span class="bold">bucket-compare</span> compares meals in buckets against each other.</p>
                <p>The <span class="bold">rule source</span> field defines which data set is used in every comparison.</p>
              </InfoBox>
            </div>
          </div>
        </div>
        <div class="side">
          <div class="box">
            <div class="diagonal-wrap">
              <button class="large-button" @click="onSubmit">Save Menu Rules</button>
            </div>
          </div>
          <InfoBox title="Tip: Optimizing Rules">
            <p>Every menu must conform to all the rules listed here, however failing one rule will prevent any additional rules from being tested.</p>
            <p>Therefore, it is recommended to add more rules rather than combine multiple conditions in a single rule, unless it is necessary.</p>
            <p>An ideal order for your rules should start with rules which have simple tests and filter out a lot of results, leaving fewer results to deal with the more expensive tests ahead.</p>
          </InfoBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "@/lib/nutree/Config";
import InfoBox from "@/components/ui/InfoBox";
import Draggable from "vuedraggable";
import RuleForm from "@/components/forms/rules/RuleForm";
import {mapActions, mapGetters} from "vuex";
import {delayedIf} from "@/lib/Async";

export default {
name: "MenuRules",
  components: {RuleForm, InfoBox, Draggable},
  data() {
    return {
      dragOrder: [],
      rules: [],
      newName: '',
      newSource: '',
      newType: '',
      sources: Config.sources,
    }
  },

  computed: {
    ...mapGetters(['allPlanIngredients', 'allPlanMealTypes', 'allMealTypes', 'currentPlan','myPlan','isLoggedIn']),
    addedMealTypes() { return this.allMealTypes ? this.allPlanMealTypes.map(it => this.allMealTypes.find(mt => mt.id === (it.meal_type_id ? it.meal_type_id:it))) : null },
    selectSources() {
      return this.sources ? Object.keys(this.sources).map(slug => ({...this.sources[slug], slug })) : []
    }
  },

  methods: {
    ...mapActions(['getPlanIngredients', 'getPlanMealTypes', 'getMealTypes', 'setLoading', 'setSuccess', 'setPlanRules','getMyPlan']),

    onRemove(index) {
      this.rules.splice(index, 1);
    },
    onAdd() {
      if (!(this.newName && this.newType && this.newSource)) return;

      const rule = {
        name: this.newName,
        type: this.newType,
        source: this.newSource,
        data : []
      }

      this.rules.push(rule);
      this.newName = '';
      this.newType = this.newSource = null;
    },
    onChangeData(index, data) {
      this.rules[index].data = data;
    },
    onChangeName(index, name) {
      this.rules[index].name = name;
    },
    onDragEnd(data) {
      const rule = this.rules.splice(data.oldIndex, 1);
      this.rules.splice(data.newIndex, 0, rule[0]);
    },

    async onSubmit() {
      await this.setPlanRules([this.currentPlan, this.rules]);
      await this.setSuccess("Saved Menu Rules")
    }
  },

  async mounted() {
    await this.setLoading(true);
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else {
        if (!this.myPlan) await this.getMyPlan()
        if (!this.allPlanMealTypes.length) await this.getPlanMealTypes()
        if (!this.allMealTypes.length) await this.getMealTypes()

        if (this.myPlan && this.myPlan.rules) {
          this.rules = [...this.myPlan.rules]
        }
      }
      await this.setLoading(false)
    },800)
  }
}
</script>
