<template>
  <div class="box">
    <h3>Rules</h3>
    <div v-for="(rule, index) in rules" :key="index">
      <input type="checkbox" :checked="!rule.inactive" @change="onRuleToggle(index)" />
      {{rule.name}}
    </div>
  </div>
</template>
<script>
export default {
    name:"RulesSwitcher",
    props: ['rules'],
    data() {
        return {

        }
    },
    methods: {
      onRuleToggle(index) {
            this.$emit('change', index);
        }
    },
}
</script>
