import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        prefGroups : []
    },
    getters : {
        allPrefGroups: state => state.prefGroups
    },
    actions : {
        async deletePrefGroup({commit}, id) {
            await ApiRequest(ApiCall(Api.DeletePrefGroup, id))
            return await commit('deletePrefGroup', id)
        },
        async getPrefGroups({commit}) {
            const prefGroups = await ApiRequest(ApiCall(Api.ListPrefGroups));
            return await commit('setPrefGroups', prefGroups);
        },
        async updatePrefGroup({commit}, [prefGroup, id]) {
            await ApiRequest(ApiCall(Api.UpdatePrefGroup, prefGroup, id))
            return await commit('savePrefGroup', [prefGroup, id])
        },
        async createPrefGroup({commit}, prefGroup) {
            const res = await ApiRequest(ApiCall(Api.CreatePrefGroup, prefGroup))
            prefGroup.id = res.id
            return await commit('savePrefGroup', [prefGroup, null])
        },

    },
    mutations : {
        setPrefGroups: (state, prefGroups) => state.prefGroups = prefGroups,
        savePrefGroup: (state, [prefGroup, id]) => {
            if (id) {
                state.prefGroups = state.prefGroups.map(it => it.id === prefGroup.id ? prefGroup : it)
            }
            else {
                state.prefGroups = [...state.prefGroups, prefGroup];
            }
            return state
        },
        deletePrefGroup(state, id) {
            state.prefGroups = state.prefGroups.filter(it => it.id !== id)
        },
    }
}
