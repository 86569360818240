<template>
  <ul class="tag-list">
    <li v-for="item in items" :key="item.item_id" class="tag">
      <span>{{itemValue(item)}}</span>
      <b v-if="secondary" class="item-secondary">{{item[secondary]}}{{secondarySuffix||''}}</b>
      <span v-if="!passive" class="close-btn" @click="() => removeItem(item.id)"></span>
    </li>
    <li v-if="items.length === 0">{{this.emptyMessage || '(No items found)'}}</li>
  </ul>
</template>

<script>
export default {
  name: "ItemList",
  props : ['allItems', 'itemKey', 'items', 'prop','secondary','secondarySuffix','passive', 'emptyMessage'],
  methods : {
    removeItem(id) {
      this.$emit('remove-item', id)
    },
    itemValue(t) {
      const item = this.allItems.find(it => it.id === t[this.itemKey]);
      if (item) return item[this.prop]
    },
  }
}
</script>
