<template>
  <ul :class="cls">
    <li v-for="(item, index) in items" :key="index" :class="matched(item)?'active':''" @click="onClick(item)">
      <span>{{item[prop]}}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BooleanList",
  props:['items','selected', 'prop', 'inline','match'],
  computed: {
    cls() {return 'boolean-list' + (this.inline&&' inline')}
  },
  methods : {
    matched(item) {
      if (this.match) return this.selected.indexOf(item[this.match]) > -1
      return this.selected.indexOf(item) > -1
    },
    onClick(item) {
      this.$emit('change', item, !(this.selected.indexOf(item[this.prop]) > -1));
    }
  }
}
</script>
