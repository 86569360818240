<template>
  <div v-if="plan" class="panel inline">
    <div class="panel-header">
      <p>Plan: <b>{{plan.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="plan" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="plan.id">
              <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" :on-submit="setLocale" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ plan.id?'Update Plan':'Create Plan' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import planFields from "@/lib/form-fields/plan";
import AppForm from "@/components/ui/AppForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import LocaleForm from "@/components/forms/LocaleForm";
import {actionIf, delayedIfLoading} from "@/lib/Async";

const localeFields = [
  {name: "name", label: "Name", type: "text"},
  {name: "description", label: "Description", type: "textarea"},
]
export default {

  name: "Plan",
  components: {LocaleForm, AppForm },
  data() {
    return {
      fields : planFields,
      localeFields,
      plan : null,
      locales : null,
      settingLocale : null
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlans', 'allLocales']),
  },

  methods: {
    ...mapActions(['getPlans','setLoading','setSuccess','getLocales','updatePlan','createPlan']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.plan.id) { //update
        await this.updatePlan([this.plan, this.plan.id])
      }
      else { // create new
        await this.createPlan(this.plan);
        if (this.plan.id) {
          await this.$router.push('/plan/'+this.plan.id)
          await this.initData()
          this.plan = {...this.plan }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Plan saved");
    },

    onUpdate(values) {
      this.plan = {...this.plan, ...values};
    },

    async initData() {
      await actionIf([
        [!this.allPlans.length, this.getPlans],
        [!this.allLocales.length, this.getLocales],
      ])

      this.plan = !isNaN(this.$route.params.id*1) ? this.allPlans.find(it => it.id === this.$route.params.id*1) :
          {name: "New Plan"}

      if (this.plan.id) await this.getPlanLocales()
    },

    async getPlanLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetPlanLocales, this.plan.id))
    },

    async createPlanLocale(plan_id, locale_id, name, description) {
      return await ApiRequest(ApiCall(Api.CreatePlanLocale, { locale_id, name, description },  plan_id))
    },
    async updatePlanLocale(plan_id, locale_id, name, description) {
      return await ApiRequest(ApiCall(Api.UpdatePlanLocale, {name, description},  plan_id+'/'+locale_id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    async setLocale({name, description, id}, locale_id) {
      if (id) {
        await this.updatePlanLocale(this.plan.id, locale_id, name, description)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, name, description} : it)
      }
      else {
        const res = await this.createPlanLocale(this.plan.id, locale_id, name, description)
        this.locales.push({id: res.insertId, locale_id, name, description})
      }
      this.settingLocale = null
    }
  },


}
</script>
