let ingredientsDb;

function setIngredientsDb(db) {
    ingredientsDb = db;
}

function ingredientFromDb(val, param = 'ingredient_id') {
    return ingredientsDb.find(i => i[param] === val);
}

function getAvailableMeals(buckets) {
    return buckets;
}

function mealNutrition(meal, raw = false, ingDb = null, fullDb = false) {
    if (!ingDb) ingDb = ingredientsDb;
    const meta = ['name','alternative_group','created_at','id','updated_at','tags','icon', 'instructions','serving_sizes'];
    let ingredients = [];

    if (!meal) return {};

    if (meal.ingredients && meal.ingredients instanceof Array) ingredients = meal.ingredients;
    else if (meal instanceof Array) ingredients = [...meal]

    if (meal.recipes && meal.recipes.length > 0) {
        for (let r of meal.recipes) {
            if (r.recipe && r.recipe.ingredients) {
                ingredients = [...ingredients, ...r.recipe.ingredients]
            }
        }
    }

    const nutrition = {};
    let theIng;
    if(!(ingredients instanceof Array)) console.log(ingredients)
    for (let ing of ingredients) {
        theIng = null;
        if (!ing || ing.inactive) continue;
        if (ingDb) {
            if (fullDb) {
                theIng = ingDb.find(it => it.id === ing.ingredient_id || (ing.ingredient && it.id === ing.ingredient.id));
            }
            else {
                theIng = ingDb.find(it => it.ingredient_id === ing.ingredient_id || (ing.ingredient && (ing.ingredient.ingredient_id && it.ingredient_id === ing.ingredient.ingredient_id || it.ingredient_id === ing.ingredient.id)));
            }
        }
        if (!theIng) continue;
        if (theIng.ingredient) theIng = theIng.ingredient;
        for (let nut in theIng.nutrition) {
            if (meta.indexOf(nut) > -1) continue;
            if (!nutrition[nut]) nutrition[nut] = 0;
            nutrition[nut] += (theIng.nutrition[nut] * (ing.amount_g?ing.amount_g:100) /100) * (ing.serving_amount&&ing.serving_size_id?ing.serving_amount:1);
        }
    }

    if (!raw) for (let i in nutrition) nutrition[i] = parseFloat(nutrition[i]).toFixed(2)*1;
    return nutrition;
}

function mealsNutrition(meals) {
    let r, res = {};
    if (meals.meals) meals = meals.meals;
    for (let meal of meals) {
        r = (meal.nutrition) ? meal.nutrition : mealNutrition(meal);
        for (let p in r) {
            if (isNaN(r[p]*1)) continue;
            if (!res[p]) res[p] = 0;
            res[p] += r[p]
        }
    }
    return res;
}

function NutritionSorter(res, data, params) {

    let {calories, protein, fat, powerCal, powerProtein, powerFat} = params;

    console.log("Sorting params", params)

    return res.sort((a, b) => {
        a = data['nutrition'][a];
        b = data['nutrition'][b];

        let ac = Math.abs(calories - a.calories), bc = Math.abs(calories - b.calories);
        let ap = Math.abs(protein - a.protein_g * 4), bp = Math.abs(protein - b.protein_g * 4);
        let af = Math.abs(fat - a.fat_g * 9), bf = Math.abs(fat - b.fat_g * 9);

        let aa = 0, bb = 0;
        aa += ac * powerCal;
        aa += ap * powerProtein;
        aa += af * powerFat;

        bb += bc * powerCal;
        bb += bp * powerProtein;
        bb += bf * powerFat;

        return aa < bb ? -1 : 1;
    })
}

function getDailyNutrition(plan, keySet) {
    if (plan.meals && plan.meals instanceof Array) plan = plan.meals;
    let nutrition = {}, m, meal, kkey;
    for (let i = 0; i < 5; i++) {
        kkey = parseInt(keySet[i], 36);
        if (plan[i] && keySet && plan[i][kkey]) {
            meal = plan[i][kkey];
            m = mealNutrition(meal);
            for (let n in m) {
                if (!nutrition[n]) nutrition[n] = 0;
                nutrition[n] += m[n]*1;
            }
        }
    }
    for (let i in nutrition) nutrition[i] = parseFloat(nutrition[i]).toFixed(2);
    nutrition['protein_cal'] = nutrition['protein_g'] * 4;
    nutrition['fat_cal'] = nutrition['fat_g'] * 9;

    return nutrition;
}

function replaceMealIngredients(ingredients, preferences, groups) {
    if (ingredients.ingredients && ingredients.ingredients instanceof Array) ingredients = ingredients.ingredients;
    let ingredient, preference;

    for (let i = 0; i < ingredients.length; i++) {
        ingredient = ingredientFromDb(
            (ingredients[i].ingredient?
                    (ingredients[i].ingredient.ingredient_id?ingredients[i].ingredient.ingredient_id:ingredients[i].ingredient.id):
                    (ingredients[i].ingredient_id? ingredients[i].ingredient_id:ingredients[i].id)
            )); // load tags, alt groups etc.

        if (!ingredient) {
            continue;
        }

        const group = groups.find(it => it.id === ingredient.pref_group_id)

        if (group && preferences && preferences[group.slug]) {
            if (preferences[group.slug] === "none") {
                ingredients[i].inactive = true;
                continue;
            }

            ingredients[i].inactive = false;
            preference = groupPreferences(group, preferences, ingredient.id);
            if (preference.length > 0) {
                const r = preference.find(it => it.ingredient_id === ingredients[i].ingredient.id)
                if (r) continue;
                ingredients[i] = replaceIngredient(ingredients[i], preference[0].ingredient? preference[0].ingredient: preference[0]);
            }
        }
    }
    return ingredients;
}

function groupPreferences(group, preferences) {
    const res = []
    for (let ing of ingredientsDb) {
        if (ing.pref_group_id === group.id && preferences[group.slug].indexOf(ing.slug) > -1) res.push(ing)
    }
    return res;
}

function replaceIngredient(ingredient, target) {
    if (ingredient.ingredient_id === target.ingredient_id) return;
    const fromDb = ingredient.ingredient ? ingredient.ingredient : ingredientFromDb(ingredient.ingredient_id)
    const origNutrition = fromDb.nutrition ? {...fromDb} : ingredientFromDb(ingredient.ingredient_id ? ingredient.ingredient_id: ingredient.id);
    const tData = ingredientFromDb(target.ingredient_id)

    let hasAmount = false, targetHasAmount = false;
    if (fromDb.serving_sizes) for (let size of fromDb.serving_sizes) { if (size.id && ingredient.serving_size_id) { hasAmount = true; break; } }
    if (tData.serving_sizes) for (let size of tData.serving_sizes) { if (size.id) { targetHasAmount = true; break; } }

    ingredient.ingredient = target;
    ingredient.ingredient.id = ingredient.ingredient.ingredient_id;
    // change serving size etc.
    const serving = (ingredient.serving_size_id ? ingredient.serving_size_id : '');
    if (hasAmount && targetHasAmount) {
        ingredient = optimizeServing(ingredient, serving, origNutrition, target);
    }
    else {
        if (targetHasAmount) {
            if (!ingredient.needed_amount) ingredient.needed_amount = ingredient.amount_g * origNutrition.calories;
            ingredient = optimizeServing(ingredient, '', origNutrition, target);
        }
        else {
            delete ingredient.serving_name
            delete ingredient.serving_size_id
            ingredient.amount_g = optimizeAmount(ingredient, ingredient.amount_g, origNutrition, target.nutrition);
        }
    }

    return ingredient;
}

function optimizeServing(ingredient, serving, origin, target) {
    let cal = ingredient.needed_amount ? ingredient.needed_amount : (origin.nutrition?origin.nutrition.calories:origin.calories) * ingredient.amount_g / 100 * ingredient.serving_amount,
        cal2, closest = null, closestVal = 99999, clos;

    for (let size of target.serving_sizes) {

        clos = Math.abs(cal - (size.amount_g * target.nutrition.calories/100))
            //cal - (size.amount_g * target.nutrition.calories/100) >= 0 ? Math.abs(cal - (size.amount_g * target.nutrition.calories/100)) : 6666;
        if (clos < closestVal) {
            closestVal = clos;
            closest = size;

            cal2 = target.nutrition.calories * size.amount_g / 100;
        }
    }

    if (closest) { // match closest

        while (ingredient.serving_amount > 1 && Math.abs(cal2*(ingredient.serving_amount-1)-cal) < Math.abs(cal2*(ingredient.serving_amount)-cal)) {
            ingredient.serving_amount--;
        }
        while (Math.abs(cal2*(ingredient.serving_amount+1)-cal) < Math.abs(cal2*(ingredient.serving_amount)-cal)) {
            ingredient.serving_amount++;
        }
        // try finding a bigger size that is identical in grams
        if (ingredient.serving_amount > 1) {
            for (let size of target.serving_sizes) {
                if (size.amount_g === closest.amount_g * ingredient.serving_amount) {
                    closest = size;
                    ingredient.serving_amount = 1;
                    break;
                }
            }
        }
        ingredient.amount_g = closest.amount_g;
        ingredient.serving_size_id = closest.serving_size_id;
    }
    if (!ingredient.needed_amount) ingredient.needed_amount = cal;

    return ingredient;
}

function optimizeAmount(ingredient, amount, origin, target) { // by... fat?
    if (ingredient.needed_amount) return Math.round((ingredient.needed_amount) / (target.nutrition?target.nutrition.calories:target.calories) * 100);
    return Math.round(((origin.nutrition?origin.nutrition.calories:origin.calories) /100* amount) / (target.nutrition?target.nutrition.calories:target.calories) * 100);
}

function applyPreferencesToMeal(meal, preferences, groups) {

    if (!preferences) preferences = {};
    meal.ingredients = replaceMealIngredients(meal.ingredients, preferences, groups);
    meal.nutrition = mealNutrition(meal);

    return meal;
}

function applyPreferencesToMeals(meals, preferences, groups) {
    for (let i = 0; i < meals.length; i++) {
        meals[i] = applyPreferencesToMeal(meals[i], preferences, groups);
    }
    return meals;
}

function menuMealReplacements(planMealStyles, menuMeals, planMeals, offsets) {
    let replacementStyles = planMealStyles.filter(it => !it.is_main).map(it => it.meal_style_id? it.meal_style_id:it.id)
    let meal;
    let replacementMeals = planMeals.filter(it => {
        meal = it.meal
        if (!meal) return true
        return meal.meal_style_id && replacementStyles.indexOf(meal.meal_style_id) > -1
    })

    const mealTypeReplacements = {}
    replacementMeals.forEach(meal => {
        if (!mealTypeReplacements[meal.meal_type_id]) mealTypeReplacements[meal.meal_type_id] = []
        mealTypeReplacements[meal.meal_type_id].push(meal)
    })

    let replacements = [], candidates, nutrition, target;
    for (let m = 0; m < menuMeals.length; m++) {
        meal = menuMeals[m]
        if (meal) {
            replacements[m] = []
            target = mealNutrition(meal)
            candidates = mealTypeReplacements[meal.meal_type_id]
            if (candidates) {
                for (let c of candidates) {
                    nutrition = mealNutrition(c)
                    if (
                        (nutrition.calories >= target.calories * offsets.calories.min && nutrition.calories <= target.calories * offsets.calories.max) &&
                        (nutrition.protein_g >= target.protein_g * offsets.protein.min && nutrition.protein_g <= target.protein_g * offsets.protein.max) &&
                        (nutrition.fat_g >= target.fat_g * offsets.fat.min && nutrition.fat_g <= target.protein_g * offsets.fat.max)
                    )
                        replacements[m].push(c)
                }
            }
        }
    }
    return replacements
}

function closestMealIndex(meals, calories, protein_g, fat_g) {
    let nut, distance, minDistance = 999999, closest;

    for (let m = 0; m < meals.length; m++) {
        nut = mealNutrition(meals[m])
        distance = Math.abs(nut.calories-calories) + Math.abs(nut.protein_g-protein_g)*4 + Math.abs(nut.fat_g-fat_g)*9
        if (distance < minDistance) {
            minDistance = distance
            closest = m
        }
    }
    return closest
}

module.exports = {
    setIngredientsDb,
    getAvailableMeals,
    getDailyNutrition,
    mealNutrition,
    NutritionSorter,
    applyPreferencesToMeals,
    menuMealReplacements,
    mealsNutrition,
    closestMealIndex,
}
