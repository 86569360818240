import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        planGoals : []
    },
    getters : {
        allPlanGoals: state => state.planGoals
    },
    actions : {
        async deletePlanGoal({commit}, id) {
            await ApiRequest(ApiCall(Api.DeletePlanGoal, id))
            return await commit('deletePlanGoal', id)
        },
        async getPlanGoals({commit}) {
            const planGoals = await ApiRequest(ApiCall(Api.ListPlanGoals));
            return await commit('setPlanGoals', planGoals);
        },
        async updatePlanGoal({commit}, [prefGroup, id]) {
            await ApiRequest(ApiCall(Api.UpdatePlanGoal, prefGroup, id))
            return await commit('savePlanGoal', [prefGroup, id])
        },
        async createPlanGoal({commit}, prefGroup) {
            const res = await ApiRequest(ApiCall(Api.CreatePlanGoal, prefGroup))
            prefGroup.id = res.id
            return await commit('savePlanGoal', [prefGroup, null])
        },

    },
    mutations : {
        setPlanGoals: (state, planGoals) => state.planGoals = planGoals,
        savePlanGoal: (state, [prefGroup, id]) => {
            if (id) {
                state.planGoals = state.planGoals.map(it => it.id === prefGroup.id ? prefGroup : it)
            }
            else {
                state.planGoals = [...state.planGoals, prefGroup];
            }
            return state
        },
        deletePlanGoal(state, id) {
            state.planGoals = state.planGoals.filter(it => it.id !== id)
        },
    }
}
