import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        mealStyles : [],
        planMealStyles: []
    },
    getters : {
        allMealStyles: state => state.mealStyles,
        allPlanMealStyles: state => state.planMealStyles,
    },
    actions : {
        async deleteMealStyle({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteMealStyle, id))
            return await commit('deleteMealStyle', id)
        },
        async getMealStyles({commit}) {
            const mealStyles = await ApiRequest(ApiCall(Api.ListMealStyles));
            return await commit('setMealStyles', mealStyles);
        },
        async updateMealStyle({commit}, [mealStyle, id]) {
            await ApiRequest(ApiCall(Api.UpdateMealStyle, mealStyle, id))
            return await commit('saveMealStyle', [mealStyle, id])
        },
        async createMealStyle({commit}, mealStyle) {
            const res = await ApiRequest(ApiCall(Api.CreateMealStyle, mealStyle))
            mealStyle.id = res.insertId
            return await commit('saveMealStyle', [mealStyle, null])
        },

        async getPlanMealStyles({commit}) {
            const mealStyles = await ApiRequest(ApiCall(Api.ListPlanMealStyles))
            return await commit('setPlanMealStyles', mealStyles)
        },

        async setPlanMealStyles({commit}, meal_styles) {
            await ApiRequest(ApiCall(Api.SetPlanMealStyles, {meal_styles}))
            return await commit('setPlanMealStyles', meal_styles);
        },

    },
    mutations : {
        setPlanMealStyles: (state, mealStyles) => state.planMealStyles = mealStyles,
        setMealStyles: (state, mealStyles) => state.mealStyles = mealStyles,
        saveMealStyle: (state, [mealStyle, id]) => {
            if (id) {
                state.mealStyles = state.mealStyles.map(it => it.id === mealStyle.id ? mealStyle : it)
            }
            else {
                state.mealStyles = [...state.mealStyles, mealStyle];
            }
            return state
        },
        deleteMealStyle(state, id) {
            state.mealStyles = state.mealStyles.filter(it => it.id !== id)
        },
    }
}
