import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        users : [],
        savedUser: null
    },
    getters : {
        allUsers: state => state.users,
        savedUser: state => state.savedUser,
    },
    actions : {
        async deleteUser({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteUser, id))
            return await commit('deleteUser', id)
        },
        async getUsers({commit}) {
            const users = await ApiRequest(ApiCall(Api.ListUsers));
            return await commit('setUsers', users);
        },
        async updateUser({commit, dispatch}, [user, id]) {
            const res = await ApiRequest(ApiCall(Api.UpdateUser, user, id))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                user.id = res.id
                await dispatch('setSuccess', 'User Updated')
                return await commit('saveUser', [user, id])
            }
        },
        async createUser({commit, dispatch}, user) {
            const res = await ApiRequest(ApiCall(Api.CreateUser, user))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                user.id = res.insertId || res.id
                await dispatch('setSuccess', 'User Created')
                return await commit('saveUser', [user, null])
            }
        },
    },
    mutations : {
        setUsers: (state, users) => state.users = users,
        saveUser: (state, [user, id]) => {
            state.savedUser = user
            if (id) {
                state.users = state.users.map(it => it.id === user.id ? user : it)
            }
            else {
                state.users = [...state.users, user];
            }
            return state
        },
        deleteUser(state, id) {
            state.users = state.users.filter(it => it.id !== id)
        },
    }
}
