<template>
  <div class="form-padded">
    <div v-for="(comp, index) in d" :key="index" class="rule-entry box">
      <div class="entry-inner flexy">
        <a @click="remove(index)" class="danger-btn">x</a>
        <DataParam :type="comp[0]" :value="comp[1]" :inputs="inputs" :buckets="buckets" :sources="sources" :rule="rule" :on-change="(type, value) => changeParam(index, 0, 1, type, value)" />
        <DataParam :type="comp[2]" :value="comp[3]" :inputs="inputs" :buckets="buckets" :sources="sources" :rule="rule" :on-change="(type, value) => changeParam(index, 2, 3, type, value)" />
        <input type="number" :value="comp[4]" @change="(e) => changeVal(index, 4, e.target.value)" placeholder="Min factor" />
        <input type="number" :value="comp[5]" @change="(e) => changeVal(index, 5, e.target.value)" placeholder="Max factor" />
      </div>
    </div>
    <div class="button-row flexy">
      <button class="primary" @click="add()">Add Condition</button>
      <button class="danger" @click="onRemove()">Delete Rule</button>
    </div>
  </div>
</template>

<script>
import DataParam from "@/components/forms/rules/DataParam";

export default {
  name: "RangeFilterRule",
  props: ["rule", "buckets", "sources", "inputs", "onChange", "onRemove"],
  components: {DataParam},


  data() {
    return {
      d : null
    }
  },

  mounted() {
    this.d = this.rule.data;
  },

  methods : {
    remove(index) {
      this.d.splice(index, 1);
    },
    add() {
      this.d.push(["value",1,"value",1.18, 0.8, 1.2])
    },
    changeVal(index, prop, value) {
      this.d[index][prop] = value * 1;
      this.onChange(this.d);
    },
    changeParam(index, indexType, indexValue, type, value) {
      this.d[index][indexType] = type;
      this.d[index][indexValue] = value;
      this.onChange(this.d);
    }
  },

}
</script>
