<template>
  <div class="rule form-group" v-if="sources">
    <div class="flexy"><div class="handle-icon"></div></div>
    <div class="rule-info even form-padded">
      <div class="diagonal-wrap wrap-bottom">
        <input type="text" class="rename" :value="rule.name" @change="(e) => onChangeName(e.target.value)" />
      </div>
      <span>Type: <b>{{rule.type}}</b></span>
      <span>Source: <b>{{sources[rule.source] ? sources[rule.source].name : rule.source}}</b></span>
      <i v-if="sources[rule.source] && sources[rule.source].props">Contains Parameters</i>
    </div>
    <BucketCompareRule v-if="rule.type === 'bucket-compare'" :rule="rule" :buckets="buckets" :sources="sources" :on-change="onChange" :on-remove="remove" />
    <BooleanFilterRule v-if="rule.type === 'boolean'" :rule="rule" :buckets="buckets" :sources="sources" :inputs="inputs" :on-change="onChange" :on-remove="remove" />
    <RangeFilterRule v-if="rule.type === 'sum-range'" :rule="rule" :buckets="buckets" :sources="sources" :inputs="inputs" :on-change="onChange" :on-remove="remove" />

  </div>
</template>

<script>
import BooleanFilterRule from "@/components/forms/rules/types/BooleanFilterRule";
import BucketCompareRule from "@/components/forms/rules/types/BucketCompareRule";
import RangeFilterRule from "@/components/forms/rules/types/RangeFilterRule";
export default {
  name: "RuleForm",
  components: {RangeFilterRule, BucketCompareRule, BooleanFilterRule, },
  props: ["rule", "buckets", "sources", "inputs", "onChangeData", "onChangeName", "onRemove"],

  methods : {
    onChange(index, prop, value) {
      this.onChangeData(index, prop, value);
    },
    remove() {
      this.onRemove()
    }
  },

}
</script>
