<template>
  <div class="box">
    <div class="flexy wrap">
      <div class="menu-preferences-list" v-for="(opts, group) in this.preferenceOptions" :key="group.id">
        <button v-for="(option, index) in opts" :key="index" @click="setMenuPreference(group, option)"
                :class="optionClass(group,option)" :title="option.name">{{option.name}}</button>
      </div>
      <button @click="clearPreferences" class="clear-preferences">Clear Preferences</button>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    props: ['onChange', 'resetPreferences'],
    data() {
        return {
            preferenceOptions : {},
            preferences : {},
        }
    },
  computed: {...mapGetters(['allPrefGroups','allPlanIngredients'])},

    async mounted() {

      if (this.allPlanIngredients.length <= 0) await this.getPlanIngredients();
      if (this.allPrefGroups.length <= 0) await this.getPrefGroups();
      const options = {}; let grp;
      for (let group of this.allPrefGroups) {
        options[group.slug] =  []
        this.$set(this.preferences, group.slug, [])
      }
      for (let ing of this.allPlanIngredients) {
        if (!ing.pref_group_id) continue;
        grp = this.allPrefGroups.find(it => it.id === ing.pref_group_id)
        if (grp) options[grp.slug].push(ing)
      }

      this.preferenceOptions = options;
    },

    methods: {
      ...mapActions(['getPrefGroups','getPlanIngredients']),
      clearPreferences() {
        for (let group of this.allPrefGroups) {
          this.$set(this.preferences, group.slug, [])
        }
        this.onChange(this.preferences);
      },

      setMenuPreference(slug, value) {
            const sg = this.allPrefGroups.find(it => it.slug === slug)
            if (sg) {
              if (!this.preferences[slug]) this.preferences[slug] = []
              if (sg.max === 1)
                this.$set(this.preferences, slug, [value.slug]);
              else if (this.preferences[slug].indexOf(value.slug) > -1 && this.preferences[slug].length > 1) {
                this.preferences[slug].splice(this.preferences[slug].indexOf(value.slug), 1)
                this.$set(this.preferences, slug, this.preferences[slug]);
              }
              else {
                if (this.preferences[slug].length >= sg.max) this.preferences[slug].pop()
                this.$set(this.preferences, slug, [...this.preferences[slug], value.slug])
              }
            }
            this.onChange(this.preferences);
        },
        optionClass(slug, value) {
            return this.preferences[slug] && this.preferences[slug].indexOf(value.slug) > -1 ? 'active' : '';
        },
    },

}
</script>
