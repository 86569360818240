import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        markets : []
    },
    getters : {
        allMarkets: state => state.markets
    },
    actions : {
        async deleteMarket({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteMarket, id))
            return await commit('deleteMarket', id)
        },
        async getMarkets({commit}) {
            const markets = await ApiRequest(ApiCall(Api.ListMarkets));
            return await commit('setMarkets', markets);
        },
        async updateMarket({commit}, [market, id]) {
            await ApiRequest(ApiCall(Api.UpdateMarket, market, id))
            return await commit('saveMarket', [market, id])
        },
        async createMarket({commit}, market) {
            const res = await ApiRequest(ApiCall(Api.CreateMarket, market))
            market.id = res.insertId
            return await commit('saveMarket', [market, null])
        },

    },
    mutations : {
        setMarkets: (state, markets) => state.markets = markets,
        saveMarket: (state, [market, id]) => {
            if (id) {
                state.markets = state.markets.map(it => it.id === market.id ? market : it)
            }
            else {
                state.markets = [...state.markets, market];
            }
            return state
        },
        deleteMarket(state, id) {
            state.markets = state.markets.filter(it => it.id !== id)
        },
    }
}
