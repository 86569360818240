<template>
  <div class="panel" v-if="meta">
    <div class="panel-body">
      <div class="panel-padding">
        <h2>Analytics</h2>
        <div class="even">
          <div>
            <div class="meta">
              <h3><span class="glow-text">Performance</span></h3>
              <div>
                Last iteration time: <b>{{ meta.time }}ms</b>
              </div>
              <div>
                Initial Results: <b>{{ meta.initial }}</b>
              </div>
              <div>
                Valid Results: <b>{{ meta.valid }}</b>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <h3><span class="glow-text">Valid Result Breakdown</span></h3>
            <p class="pad-bot">How many results were find and their breakdown over the different property ranges.</p>
            <div class="even" v-if="nutrition">
              <div>
                <p>Calories</p>
                <histogram :data="calories_histogram" attr="calories" :center="calories" :sampled="nutrition.calories" color="#4190e9" />
              </div>
              <div>
                <p>Protein</p>
                <histogram :data="protein_histogram" attr="protein" :center="protein" :sampled="nutrition.protein_g*4" color="#eb264b" />
              </div>
              <div>
                <p>Fat</p>
                <histogram :data="fat_histogram" attr="fat" :center="fat" :sampled="nutrition.fat_g*9" color="#a4b232" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Histogram from "./Histogram";

export default {

    data() {
        return {
            calories_histogram: null,
            protein_histogram: null,
            fat_histogram: null
        }
    },

    props: ['meta', 'calories','protein','fat', 'nutrition'],
    components: { Histogram },

    methods: {
        setHistogramData() {
            this.calories_histogram = this.meta.results.map(it => it.calories);
            this.protein_histogram = this.meta.results.map(it => it.protein_g * 4);
            this.fat_histogram = this.meta.results.map(it => it.fat_g * 9);
        }
    },

    mounted() {
        if (this.meta && this.meta.results) this.setHistogramData()
    },

    watch : {
        nutrition() { this.setHistogramData()},
        meta() {
            this.setHistogramData()
        }
    },
}
</script>
