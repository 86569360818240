<template>
  <div v-if="planGoal" class="panel inline">
    <div class="panel-header">
      <p>Goal: <b>{{planGoal.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="planGoal" @update="onUpdate" />
            <GoalReductionForm :reductions="planGoal.reduction_rules" @add="onAddReduction" @remove="onRemoveReduction" />
            <NutritionGoalForm :nutritionGoals="planGoal.nutrition_goals" @add="onAddNutritionGoal" @remove="onRemoveNutritionGoal" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ planGoal.id?'Update Goal':'Create Goal' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import planGoalFields from "@/lib/form-fields/planGoal";
import AppForm from "@/components/ui/AppForm";
import {delayedIfLoading} from "@/lib/Async";
import GoalReductionForm from "@/components/forms/GoalReductionForm.vue";
import NutritionGoalForm from "@/components/forms/NutritionGoalForm.vue";

export default {

  name: "PlanGoal",
  components: {NutritionGoalForm, GoalReductionForm, AppForm, },
  data() {
    return {
      fields : planGoalFields,
      planGoal : null,
      ingredients: [],
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanGoals']),
  },

  methods: {
    ...mapActions(['getPlanGoals', 'getPlanIngredients', 'setLoading', 'setSuccess', 'updatePlanGoal', 'createPlanGoal']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.planGoal.id) { //update
        await this.updatePlanGoal([this.planGoal, this.planGoal.id])
      } else { // create new
        await this.createPlanGoal(this.planGoal);
        if (this.planGoal.id) {
          await this.$router.push('/plan-goal/' + this.planGoal.id)
          await this.initData()
          this.planGoal = {...this.planGoal}
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Preference Group saved");
    },

    onUpdate(values) {
      this.planGoal = {...this.planGoal, ...values};
    },

    async onAddReduction(reduction) {
      if (!this.planGoal.reduction_rules) this.planGoal.reduction_rules = []
      this.planGoal.reduction_rules.push(reduction)
    },

    onRemoveReduction(reductionIndex) {
      this.planGoal.reduction_rules.splice(reductionIndex, 1)
    },

    async onAddNutritionGoal(nutritionGoal) {
      if (!this.planGoal.nutrition_goals) this.planGoal.nutrition_goals = []
      this.planGoal.nutrition_goals.push(nutritionGoal)
    },

    async onRemoveNutritionGoal(nutritionGoalIndex) {
      this.planGoal.nutrition_goals.splice(nutritionGoalIndex, 1)
    },

    async initData() {
      if (this.allPlanGoals.length <= 0) await this.getPlanGoals();
      this.planGoal = !isNaN(this.$route.params.id * 1) ? this.allPlanGoals.find(it => it.id === this.$route.params.id * 1) : {ingredients:[]}
    },
  },



}
</script>
