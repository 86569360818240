<template>
  <div class="navbar">
    <div class="loading-bar" v-if="isLoading"></div>
    <div class="success-bar" v-if="success">{{success}}</div>
    <div class="error-bar flexy" v-if="appError"><b>Error</b><span>{{appError}}</span></div>
    <div class="title">
      <div :class="'theme-switcher' + (lightTheme?' active':'')" @click="toggleTheme"></div>
      <h1><router-link to="/">{{ myPlan && myPlan.editor_title ? myPlan.editor_title : 'Fito Nutrition' }}</router-link></h1>
    </div>
    <div class="menu" v-if="!currentPlan && permissionsContain(currentUser, 'EDITOR')">
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'ADMIN_USERS')" to="/users"><span>Users</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'ADMIN_ROLES')" to="/roles"><span>Roles</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_LOCALE')" to="/locales"><span>Locales</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_MEAL_TYPES')" to="/meal-types"><span>Meal Types</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_MEAL_TYPES')" to="/meal-styles"><span>Meal Styles</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_INGREDIENTS')" to="/ingredients"><span>Ingredients</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_RECIPES')" to="/recipes"><span>Recipes</span><b class="error-badge" v-if="recipeErrors">{{recipeErrors}}</b></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_MEALS')" to="/meals"><span>Meals</span><b class="error-badge" v-if="mealErrors">{{mealErrors}}</b></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_PLANS')" to="/plans"><span>Plans</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_MARKETS')" to="/markets"><span>Markets</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_SERVING_SIZES')" to="/serving-sizes"><span>Serving Sizes</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_TAGS')" to="/tags"><span>Tags</span></router-link>
    </div>
    <div class="plan-menu" v-if="currentPlan">
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_MEALS')" to="/plan-meals"><span>My Meals</span><b class="error-badge" v-if="myMealsErrors">{{myMealsErrors}}</b></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_MEALS')" to="/plan-meal-replacements"><span>Meal Replacements</span><b class="error-badge" v-if="mealReplacementsErrors">{{mealReplacementsErrors}}</b></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_INGREDIENTS')" to="/plan-ingredients"><span>My Ingredients</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_INGREDIENTS')" to="/plan-ingredient-icons"><span>Icons</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_SETTINGS')" to="/plan-groups"><span>Groups</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_SETTINGS')" to="/plan-goals"><span>Goals</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_INGREDIENTS')" to="/plan-menu"><span>Menu Builder</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_SETTINGS')" to="/plan-settings"><span>Plan Settings</span></router-link>
    </div>
    <div class="user" v-if="isLoggedIn">
      <div class="user-info">
        <span>Logged in as</span>
        <b>{{currentUser.username}}</b>
        <a class="button" @click="doLogout" href="javascript:void(0)">Log Out</a>
      </div>
      <div class="plan-selector" v-if="hasPermission(currentUser, 'EDITOR_PLANS')">
        Plan:
        <div class="diagonal-wrap">
          <v-select @input="setPlan" :options="selectPlans" :reduce="it=>it.id" label="name" placeholder="- Select Plan -" :value="plan?plan.id:0" :clearable="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "Navbar",

  computed: {
    ...mapGetters(['isLoggedIn', 'isLoading', 'success', 'appError', 'currentUser','currentPlan','myPlan','allPlans','allMeals','allRecipes','allPlanMeals','allPlanIngredients','allPlanMealStyles']),
    plan() { return this.allPlans.length ? this.allPlans.find(it => it.id === this.currentPlan):{}  },
    selectPlans() { return [{id: 0, name: "- Fito Database -"}, ...this.allPlans]},
    mealErrors() {
      if (!this.allMeals) return ''
      let c = 0;
      for (let meal of this.allMeals) {
        for (let ing of meal.ingredients) { if (!ing.ingredient) c++ } // missing ingredients
      }
      return c > 0 ? c : ''
    },
    recipeErrors() {
      if (!this.allRecipes) return ''
      let c = 0;
      for (let meal of this.allRecipes) {
        for (let ing of meal.ingredients) { if (!ing.ingredient) c++ } // missing ingredients
      }
      return c > 0 ? c : ''
    },
    myMealsErrors() {
      return this.missingIngredients(true)
    },
    mealReplacementsErrors() {
      return this.missingIngredients(false)
    }
  },

  data() {
    return {
      lightTheme : false,
    }
  },

  watch : {
    async currentPlan() {
      if (this.currentPlan) {
        await actionIf([
            [!this.allPlanMeals.length, await this.getPlanMeals],
            [!this.allPlanIngredients.length, await this.getPlanIngredients],
            [!this.allPlanMealStyles.length, await this.getPlanMealStyles],
        ])
      }
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
      [this.isLoggedIn && !this.myPlan, await this.getMyPlan],
      [!this.allPlanMeals.length, await this.getPlanMeals],
      [!this.allPlanIngredients.length, await this.getPlanIngredients],
      [!this.allPlanMealStyles.length, await this.getPlanMealStyles],
    ]), ()=> this.isLoggedIn, () => this.$router.push('/'))

    if (localStorage.getItem('theme') === 'light' && !this.lightTheme) this.toggleTheme()
  },

  methods : {
    ...mapActions(['logout', 'getPlans', 'setCurrentPlan','getMyPlan' ,'getMeals','getRecipes','getPlanMeals','getPlanIngredients','getPlanMealStyles']),

    hasPermission(user, permission) {
      return user && user.permissions.find(it => it === permission)
    },

    missingIngredients(isMain = true) {
      if (!this.allPlanMeals.length || !this.allMeals.length || !this.allPlanIngredients.length || !this.allRecipes.length) return 0
      let c = 0, match, realMeal, style, recipe;

      for (let meal of this.allPlanMeals) {
        realMeal = this.allMeals.find(it => it.id === meal.meal_id)
        if (realMeal && realMeal.meal_style_id) {
          style = this.allPlanMealStyles.find(it => it.id === realMeal.meal_style_id)
          if (!style || (style.is_main !== isMain)) continue;
        }

        for (let ing of meal.ingredients) {
          if (!ing.ingredient) {
            match = this.allPlanIngredients.find(it => it.ingredient_id === ing.ingredient_id)
            if (!match) {
              c++
            }
          }
        }
        for (let rec of meal.recipes) {
          if (!rec || !rec.recipe) continue;
          recipe = this.allRecipes.find(it => it.id === rec.recipe_id)
          for (let ing of recipe.ingredients) {
            if (ing.ingredient.id) {
              match = this.allPlanIngredients.find(it => it.ingredient_id === ing.ingredient.id)
              if (!match) c++
            } else {
              match = this.allPlanIngredients.find(it => it.ingredient_id === ing.ingredient_id)
              if (!match) c++
            }
          }
        }
      }
      return c
    },

    async setPlan(value) {
      await this.setCurrentPlan(value)
      if (this.$router.currentRoute.path !== '/') await this.$router.push('/')
    },

    doLogout() {
      this.logout();
      this.$router.push('/')
    },

    permissionsContain(user, search) {
      return user && user.permissions && user.permissions.find(it => it.toUpperCase().indexOf(search.toUpperCase()) >= 0)
    },

    toggleTheme() {
      if (document.body.classList.contains('light')) {
        localStorage.setItem('theme','dark')
        this.lightTheme = false;
        document.body.classList.remove('light')
      }
      else {
        localStorage.setItem('theme','light')
        this.lightTheme = true;
        document.body.classList.add('light')
      }
    }
  }
}
</script>
