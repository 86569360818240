<template>
    <div class="panel inline">
      <ConfirmModal v-if="mealTypeForDeletion" item-type="Meal Type" :item-name="mealTypeForDeletion.name" :confirm="confirmDeleteMealType" :cancel="cancelDeleteMealType" />
      <ListPanel title="Meal Types" :fields="fields" :items="allMealTypes" :on-add="addNewMealType" :on-delete="askToDeleteMealType" :on-row="goto" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "MealTypes",
  components: {ListPanel, ConfirmModal },
  data() {
    return {
      mealTypeForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: 'str', width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allMealTypes.length, this.getMealTypes]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMealTypes']),
  },

  methods: {
    ...mapActions(['getMealTypes','setLoading','deleteMealType']),
    goto(mealType) {
      this.$router.push('/meal-type/'+mealType.id)
    },
    addNewMealType() {
      this.$router.push('/meal-type/new')
    },
    askToDeleteMealType(mealType) {
      this.mealTypeForDeletion = mealType;
    },
    cancelDeleteMealType() { this.mealTypeForDeletion = null },
    confirmDeleteMealType() {
      this.deleteMealType(this.mealTypeForDeletion.id)
      this.mealTypeForDeletion = null
    }
  },

}
</script>
