<template>
  <div :class="className">
    <h3>{{title}}</h3>
    <div class="form-field" v-for="(field, index) in fields" :key="index">
      <div class="form-input col">
        <div class="diagonal-wrap wrap-bottom" v-if="field.type === 'text'">
          <input type="text" v-model="values[field.name]" :placeholder="field.label" />
        </div>
        <div class="form-input col" v-if="field.type === 'textarea'">
          <textarea class="tall" v-model="values[field.name]" :placeholder="field.label"></textarea>
        </div>
      </div>
    </div>
    <div class="button-row even">
      <div :class="'diagonal-wrap square-wrap' + (okToSubmit ? '':' disabled')">
        <button @click="onSubmit">Save</button>
      </div>
      <div class="diagonal-wrap square-wrap" v-if="onCancel">
        <button @click="onCancel">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideForm",
  props: ["title", "fields", "data", "inline"],
  data() {
    return {
      values : {}
    }
  },

  computed :{
    okToSubmit() {
      return true;
    },
    className() {
      let cls = 'locale-form';
      if (this.inline) cls = ' inline';
      return cls
    }
  },

  methods : {
    onSubmit() {
      this.$emit('submit', this.values)
    },
    onCancel() {
      this.$emit('cancel')
    }
  },

  mounted() {
    for (let d in this.data) {
      this.$set(this.values, d,  this.data[d]);
    }
  },

}
</script>
