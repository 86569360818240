<template>
    <div class="panel centered">
      <div class="panel-header">
        <p class="glow-text">{{ isLoggedIn? 'Logged In.':'Log in' }}</p>
      </div>
      <div  class="panel-body">
        <div v-if="!isLoggedIn">
          <form @submit="onSubmit">
            <div class="even">
              <div class="diagonal-wrap wrap-bottom">
                <input type="text" v-model="username" placeholder="Username" />
              </div>
              <div class="diagonal-wrap wrap-bottom">
                <input type="password" v-model="password" placeholder="Password" />
              </div>
              <div class="diagonal-wrap">
                <input type="submit" value="Login" />
              </div>
            </div>
            <div class="error-panel" v-if="loginError">
              <div class="danger-btn"></div><b>Error</b> {{loginError}}
            </div>
          </form>
        </div>
        <div v-if="isLoggedIn">
          <div class="diagonal-wrap"><button @click="logout">Log Out</button></div>
        </div>
      </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';

export default {
  name: "LoginForm",
  computed: mapGetters(['isLoggedIn', 'loginError']),

  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    ...mapActions(['login', 'logout']),
    async onSubmit(e) {
      e.preventDefault()
      await this.login([this.username, this.password])
    }
  }
}
</script>

<style scoped>

</style>
