<template>
  <div class="info-box">
    <h2 v-if="title"><span class="glow-text">{{title}}</span></h2>
    <p><slot></slot></p>
  </div>
</template>

<script>
export default {
  name: "InfoBox",
  props: ['title']

}
</script>
