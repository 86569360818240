import Vue from 'vue'
import App from './App.vue'
import store from './store';
import VueRouter from 'vue-router';

import routes from "@/routes";

const router = new VueRouter({
  mode: 'hash',
  routes,
});

Vue.use(VueRouter);

// vue select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
