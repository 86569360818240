<template>
  <div class="tag-form">
    <div class="form-padded">
      <label>
      <h3>Goal Reductions</h3>
      </label>
      <div class="form-input" v-for="(dest, i) in reductions" v-bind:key="i">
        <div class="flexy">
          <div class="flex-1">Reduction: <b class="bold">{{dest.reduction}}</b></div>
          <div class="flex-1">Min. TDEE: <b class="bold">{{dest.minTdee || '-'}}</b></div>
          <div class="flex-1">Max. TDEE: <b class="bold">{{dest.maxTdee || '-'}}</b></div>
          <div class="diagonal-wrap">
            <button class="square" @click="$emit('remove', i)"><span class="icon-text">x</span></button>
          </div>
        </div>
      </div>
    </div>
      <div class="form-field">
        <label><h4>Add Reduction</h4></label>
        <div class="form-input">
          <input v-model="reduction" type="number" placeholder="Calories Reduction" />
          <input v-model="minTdee" type="number" placeholder="Min. TDEE" />
          <input v-model="maxTdee" type="number" placeholder="Max. TDEE" />
          <div class="diagonal-wrap">
            <button class="square" @click="addReduction"><span class="icon-text">+</span></button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "GoalReductionForm",
  props: ['reductions'],
  data() {
    return {
      reduction : '',
      minTdee : '',
      maxTdee : ''
    }
  },
  methods : {
    addReduction() {
      if (this.reduction) {
        this.$emit('add', {reduction: this.reduction, minTdee: this.minTdee, maxTdee: this.maxTdee})
        this.reduction = ''
        this.minTdee = ''
        this.maxTdee = ''
      }
    }
  }
}
</script>
