<template>
  <form @submit="doSubmit">
    <div v-for="(group, groupName) in groups" :key="groupName" :class="'form-group group-'+groupName">
      <div class="form-group-title" v-if="groupTitle(groupName)">
        <p>{{groupTitle(groupName)}}</p>
      </div>
      <div class="form-field" v-for="field in group" :key="field.prop">
        <label>{{field.label}}</label>
        <div class="form-input">
          <div :class="field.inputClass?' '+field.inputClass:''">
            <input v-if="field.type==='text'" type="text" :value="val(field.prop, data)" @change="(e) => update(field.prop, e.target.value)" :placeholder="field.placeholder||field.label" />
            <input v-if="field.type==='password'" type="password" @change="(e) => update(field.prop, e.target.value)" :placeholder="field.placeholder||field.label" />
            <input v-if="field.type==='number'" type="number" :value="val(field.prop, data)" @change="(e) => update(field.prop, e.target.value*1)" :placeholder="field.placeholder||field.label" />
            <input v-if="field.type==='checkbox'" type="checkbox" :value="!!val(field.prop, data)?1:0" :checked="!!val(field.prop, data)" @change="(e) => update(field.prop, e.target.checked)" :d="JSON.stringify(val(field.prop, data))" />
            <textarea v-if="field.type==='textarea'" :value="val(field.prop, data)" @change="(e) => update(field.prop, e.target.value)" :placeholder="field.placeholder||field.label" />
            <v-select v-if="field.type==='select'" :value="val(field.prop, data)" :label="field.labelKey" :reduce="it => it.id" @input="value => update(field.prop, value)" :options="meta[field.metaGroup]" :placeholder="'- Select '+field.label+' -'" />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "AppForm",

  data() {
    return {
      values : {}
    }
  },

  mounted() {
    this.values = {...this.data}
  },

  props: ["fields", "onSubmit", "data", "meta"],

  methods: {
    async doSubmit(e) {
      e.preventDefault()
      if (this.onSubmit) this.onSubmit(this.values)
    },

    update(prop, data) {
      let p = prop.split(/\./g), c
      c = this.values[p[0]];
      if (p.length > 1) {
        if (!c) c = {}
        c[p[1]] = data;
      }
      else c = data;
      this.$set(this.values, p[0], c);

      this.$emit('update', this.values);
    },

    val(prop, data) {
      let v, p = prop.split(/\./g), res = data;
      while (p.length > 0) {
        v = p.shift();
        if (typeof res[v] !== "undefined" && res[v] !== null)
          res = res[v]
        else return;
      }
      return res;
    },
    propExists(prop, data) {
      let v, p = prop.split(/\./g), res = data;
      while (p.length > 0) {
        v = p.shift();
        res = res[v]
      }
      return typeof res !== "undefined";
    },

    groupTitle(groupName) {
      for (let field of this.fields) {
        if (field.group === groupName && field.groupTitle) return field.groupTitle;
      }
    }
  },

  computed : {
    groups() {
      const groups = {}, fields = {}
      let hasGroups = false
      for (let field of this.fields) {
        fields[field.prop] = field
        if (field.group) {
          hasGroups = true
          if (!groups[field.group]) groups[field.group] = []
          groups[field.group].push(field);
        }
      }
      if (hasGroups) {
        return groups;
      }
      else {
        return {'a':fields};
      }
    }
  },

  watch : {
    data() {
      this.values = {...this.data}
    }
  }
}
</script>
