<template>
  <span class="flexy">
      <v-select v-model="typeVal" @input="change" :options="typeOptions" label="label" :reduce="it => it.value" :clearable="false" />
      <v-select v-if="typeVal === 'source' && sources[rule.source] && sources[rule.source].props" v-model="valueVal"
                @input="change" :options="sourceProps" :clearable="false" />
      <v-select v-if="typeVal === 'param'" v-model="valueVal" @input="change" :options="inputs" :clearable="false" />
      <input type="text" v-if="typeVal === 'value'" v-model="valueVal" @change="change" />
    </span>
</template>

<script>

const typeOptions = [
  { value: 'source', label: "Source"},
  { value: 'param', label: "Input Parameter"},
  { value: 'value', label: "Fixed Value"},
]

export default {
  name: "DataParam",
  props: ["buckets", "sources", "inputs", "type", "value", "rule", "onChange"],
  data() {
    return {
      typeOptions,
      typeVal : null,
      valueVal: null
    }
  },
  mounted() {
    this.typeVal = this.type;
    this.valueVal = this.value;
  },
  methods : {
    change() {
      this.onChange(this.typeVal, this.valueVal);
    }
  },

  computed : {
    sourceProps() {
      const props = [...this.sources[this.rule.source].props];
      if (this.sources[this.rule.source].computed) for (let p in this.sources[this.rule.source].computed) props.push(p)
      return props;
    }
  },
}
</script>
