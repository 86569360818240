<template>
  <div class="tag-form">
    <div class="form-input even">
      <h3>Add Market</h3>
      <div class="diagonal-wrap">
        <v-select v-model="newMarket" :options="markets" :reduce="it => it.id" label="name" placeholder="- Choose Market -" />
      </div>
      <div>
        <input v-model="newAmountG" type="number" class="unwrap" placeholder="Amount (G)" />
      </div>
      <div :class="'diagonal-wrap square-wrap' + (newMarket ? '':' disabled')">
        <button class="square" @click="addMarket"><span class="icon-text">+</span></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddMarketForm",
  props: ['markets'],
  data() {
    return {
      newMarket : '',
      newAmountG: '',
    }
  },
  methods : {
    addMarket() {
      if (this.newMarket) {
        this.$emit('add', this.newMarket, this.newAmountG)
        this.newMarket = this.newAmountG = ''
      }
    }
  }
}
</script>
