<template>
  <div class="list-panel">
    <div class="panel-header">
      <p>{{title}}</p>
      <div class="search-box">
        <div v-if="filterBy">
          <div class="diagonal-wrap">
            <v-select :value="filter" :label="filterLabel" :reduce="it => it.value?it.value:it" @input="value => updateFilter(value)" :options="meta[filterBy]" :placeholder="'- Filter -'" />
          </div>
        </div>
        <div v-if="exportFile">
          <div class="diagonal-wrap">
            <button @click="onExport">Export CSV</button>
          </div>
        </div>
        <div class="close-btn" v-if="search" @click="() => this.search = ''"></div>
        <div class="diagonal-wrap">
          <input type="text" :placeholder="'Search '+title" v-model="search" />
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="full-scroller in-panel">
        <AppList :data="filteredItems" :fields="fields" :on-click="onRow" :no-formatting="true" @delete="onDelete" />
      </div>
    </div>
    <div class="panel-floating-buttons" v-if="onAdd">
      <div class="round-glower" @click="onAdd">+</div>
    </div>
  </div>
</template>

<script>
import AppList from "@/components/ui/AppList";
import {downloadCsv} from "@/lib/Csv";
export default {
  name: "ListPanel",
  components: {AppList},
  props: ["title", "fields", "onRow", "onDelete", "items", "onAdd", "meta", "exportFile","filterBy", "filterLabel", "filterProp"],

  data() {
    return {
      search: "",
      filter: null,
    }
  },

  computed: {
    filteredItems() {

      let items = this.parsedItems || []
      if (this.search.length >= 1) {
        items = items.filter(it =>
            (Object.keys(it).map(k => it[k] && it[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1).indexOf(true) > -1) ||
            (it.tags && it.tags.find( t => t.tag && (t.tag.tag ? t.tag.tag.indexOf(this.search.toLowerCase()) > -1 : t.tag.indexOf(this.search.toLowerCase()) > -1)))
        )
      }
      if (this.filter !== null && this.filterProp) {
        items = items.filter(it => it[this.filterProp] === this.filter)
      }

      return items
    },
    parsedItems() {
      let d;
      const res = [];
      for (let item of this.items) {
        d = {}
        for (let field of this.fields) {
          if (field.prop) {
            d[field.target?field.target:field.prop] = this.val(field.prop, item, field.format)
          }
        }
        if (Object.keys(d).length > 0) res.push({...item, ...d});
      }
      return res;
    }
  },

  methods : {
    updateFilter(val) {
      this.filter = val?(typeof val.value !== "undefined"?val.value:(val[this.filterLabel]?val[this.filterLabel]:val)):null
      console.log({f:this.filter, val})
    },
    val(prop, data, format = null) {
      let v, p = prop.split(/\./g), res = data;
      while (p.length > 0) {
        v = p.shift();
        res = format ? format(res[v], res) : res[v]
      }
      return res;
    },

    onExport() {
      if (!this.exportFile) return false;
      downloadCsv(this.filteredItems, this.fields, this.exportFile);
    }
  }
}
</script>
