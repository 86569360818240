import { mealNutrition, NutritionSorter} from "@/lib/NutritionUtils";

export default {

    sources : {
        'nutrition' : {
            name: "Nutritional Values",
            props: [ "calories", "fat_g", "protein_g" ],
            accumulate: true,
            computed : {
                protein_cal: (it) => it.protein_g * 4,
                fat_cal : (it) => it.fat_g * 9,
                carbs_g : (it) => (it.calories - (it.fat_g * 9) - (it.protein_g*4))/4,
                carbs_cal : (it) => it.calories - (it.fat_g * 9) - (it.protein_g*4),
                protein_weight: (it, params) => params.weight ? it.protein_g / params.weight : it.protein_g,
            },
            fetch : (buckets, index, key) => {
                if (typeof key === "string") key = parseInt(key, 36)
                if (!buckets[index][key]) console.log({index, key: key.toString(36)})
                return mealNutrition(buckets[index][key])
            },
        },
        'meal' : {
            name: "Meal Data",
            props: [ "name", "tags", "meal_type" ],
            fetch : (buckets, index, key) => buckets[index][key]
        }
    },

    designator(it, bucket) {
        return it.meal_type_id === bucket.id
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    sorting: NutritionSorter,

}
