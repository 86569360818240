export function createCsv(rows, fields) {
    let csvContent = "", row, prop, r;

    row = fields.map(f => f.prop || f.target?JSON.stringify(f.label):'').filter(it => !!it).join(",")
    csvContent += row + "\r\n";

    rows.forEach(row => {
        r = '';
        fields.forEach(field => {
            if (!field.prop && !field.target) return;
            prop = row[field.target?field.target:field.prop]
            if (r) r+= ',';
            r += JSON.stringify(prop)
        })
        csvContent += r + "\r\n";
    });

    return csvContent;
}

export function downloadCsv(rows, fields, filename) {
    const csv = createCsv(rows, fields);
    download(csv, filename, 'text/csv;encoding:utf-8');
}

function download(content, fileName, mimeType) {
    const a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob([content], {
            type: mimeType
        }), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
}
