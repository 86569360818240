<template>
  <div class="app-list-container">
    <table :class="'app-list' + (onClick?' active':'')">
      <thead>
        <tr>
          <th v-for="field in fields" :key="field.target?field.target:field.prop" :width="field.width?field.width:'auto'" :class="field.sortable?'sortable':''" @click="headClick(field.sortable, field)">
            <span>{{field.label}}</span>
            <span v-if="sortBy === (field.target?field.target:field.prop)" :class="'sort-by-field' + (sortDir<0?' desc':'')"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in sortedData" class="listing-item" :key="item.id" @click="doClick(item)">
          <td v-for="field in fields" :key="field.target?field.target:field.prop" :class="field.primary?'primary':''">
            <div v-if="field.color && val(field, item, field.format)" class="success-btn" :title="val(field, item)" :style="{background: val(field, item)}"></div>
            <img v-if="field.image && val(field, item, field.format)" class="listing-image" :src="val(field, item)" :style="{background: item[field.imageBackground]||''}" />
            <div v-if="field.boolean && val(field, item, field.format)" class="success-btn" title="Yes"></div>
            <div v-if="field.pill && val(field, item, field.format)" :class="field.pill+'-pill'" title="Yes">{{val(field, item, field.format)}}</div>
            <div v-if="!field.boolean && !field.color && !field.image && !field.pill">{{val(field, item, field.format)}}</div>
            <ul class="list-actions" v-if="field.actions">
              <li v-for="action in field.actions" :key="action.action" @click="(e) => doAction(e, action.action, item)">
                <div :class="action.type" :title="action.tooltip">{{action.label}}</div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="sortedData.length === 0">
      <p class="box">No items were found..</p>
    </div>
  </div>
</template>

<script>
export default {

  name: "AppList",

  data() {
    return {
      values : {},
      sortBy: null,
      sortDir: 1
    }
  },

  props: ["fields", "onClick", "onSort", "data", "actions","noFormatting"],

  computed : {
    sortedData() {
      let data = this.data;
      data = data.sort((a,b) => ((isNaN(a[this.sortBy]*1) ? a[this.sortBy] < b[this.sortBy] : a[this.sortBy]*1 < b[this.sortBy]*1)?-1:1))
      if (this.sortDir === -1) data = data.reverse()
      return data;
    }
  },

  methods: {

    headClick(sortable, field) {
      if (!sortable) return false;
      const prop = field.target?field.target:field.prop;

      if (this.sortBy === prop) this.sortDir = -this.sortDir
      else {
        this.sortBy = prop;
        this.sortDir = 1
      }
    },

    async doClick(item) {
      if (this.onClick) await this.onClick(item)
    },

    async doAction(e, action, item) {
      e.stopPropagation()
      e.preventDefault()
      this.$emit(action, item)
      return false;
    },

    val(field, data, format = null) {
      if (this.noFormatting) return data[field.target?field.target:field.prop];
      let v, p = field.prop.split(/\./g), res = data;
      while (p.length > 0) {
        v = p.shift();
        res = format ? format(res[v], res) : res[v]
      }
      return res;
    },

  },

}
</script>

