<template>
  <div class="panel inline">
    <ConfirmModal v-if="ingredientForDeletion" item-type="Plan Ingredient" :item-name="ingredientForDeletion.name" :confirm="confirmDeletePlanIngredient" :cancel="cancelDeletePlanIngredient" />
    <div class="panel-body sides">
      <div class="side first">
        <div class="panel-header">
          <p>Add Ingredients to Plan</p>
          <SearchBox v-model="ingredientSearch" name="Ingredients" />
        </div>
        <div class="in-panel">
          <div class="full-scroller in-panel">
            <ListAddForm :items="filteredIngredients" :form-component="AddPlanIngredientForm" :submit="addNewPlanIngredient" />
          </div>
        </div>
      </div>
      <div class="main">
        <ListPanel title="Plan Ingredients" :fields="fields" :items="allPlanIngredients" :on-row="goto" :on-delete="askToDeletePlanIngredient" export-file="plan_ingredients.csv" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ListPanel from "@/components/ui/ListPanel";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListAddForm from "@/components/ui/ListAddForm";
import AddPlanIngredientForm from '@/components/forms/AddPlanIngredientForm';
import SearchBox from "@/components/ui/SearchBox";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "PlanIngredients",

  components: {SearchBox, ListAddForm, ConfirmModal, ListPanel },

  data() {
    return {
      AddPlanIngredientForm,
      newPlanIngredientName : '',
      newPlanIngredientType: '',
      newPlanIngredientIsSpecial: false,
      ingredientForDeletion: null,
      addIngredient: null,
      search : "",
      ingredientSearch: "",
      fields : [
        {label: "Name", prop: "name", primary: true, sortable: true },
        {label: "Icon", prop: "icon_id", boolean: true, sortable: true},
        {label: "Preference Group", width: "20%", prop: "pref_group_id", sortable: true, format: (id) => id ? (this.prefGroup(id) ? this.prefGroup(id).name : '-') : '' },
        {label: "Replacement Group", width: "20%", prop: "rep_group_id", sortable: true, format: (id) => id ? (this.repGroup(id) ? this.repGroup(id).name : '-') : '' },
        {label: "Meals", width: "8%", prop: "ingredient_id", target: "meals", sortable: true, format: (id) => this.mealsCount(id) },
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.allPlanIngredients.length, this.getPlanIngredients],
          [!this.allIngredients.length, this.getIngredients],
          [!this.allPlanMeals.length, this.getPlanMeals],
          [!this.allPrefGroups.length, this.getPrefGroups],
          [!this.allRepGroups.length, this.getRepGroups],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanIngredients', 'allPlanMeals', 'allIngredients','allPrefGroups','allRepGroups']),
    sortedPlanIngredients() {
      const sort = this.allPlanIngredients instanceof Array ? [...this.allPlanIngredients]:[]
      return sort.sort((a,b) => a.name < b.name ? -1:1)
    },
    sortedIngredients() {
      const sort = this.allIngredients instanceof Array ? [...this.allIngredients]:[]
      return sort.sort((a,b) => a.name < b.name ? -1:1)
    },

    filteredIngredients() {
      return this.ingredientSearch.length >= 1 ? this.sortedIngredients.filter(it => it.name.toLowerCase().indexOf(this.ingredientSearch.toLowerCase()) > -1) : this.sortedIngredients
    },
    addIngredients() { return [{ ingredient: this.addIngredient, amount_g: 100, serving_amount: 1}] }
  },

  methods: {
    ...mapActions(['getPlanIngredients','getPlanMeals','setLoading','createPlanIngredient','deletePlanIngredient','getIngredients', 'getPrefGroups','getRepGroups']),
    goto(ingredient) {
      this.$router.push('/plan-ingredient/'+ingredient.id)
    },
    prefGroup(id) {
      return this.allPrefGroups.find(it => it.id === id)
    },
    repGroup(id) {
      return this.allRepGroups.find(it => it.id === id)
    },
    mealsCount(id) {
      return this.allPlanMeals ? this.allPlanMeals.filter(it => it.ingredients ? it.ingredients.find(ing => ing.meal_id === it.meal_id && (ing.ingredient_id === id || (ing.ingredient&&ing.ingredient.id===id))):null).length : 0;
    },
    async addNewPlanIngredient(id, name) {
      if (!id || !name) return false;
      await this.setLoading(true);

      try {
        await this.createPlanIngredient({ingredient_id: id,  name: name.trim()})
      }
      catch(e) {
        console.log("Error!")
      }

      await this.setLoading(false);
      this.addIngredient = null;

    },
    askToDeletePlanIngredient(ingredient) {
      this.ingredientForDeletion = ingredient;
    },
    cancelDeletePlanIngredient() { this.ingredientForDeletion = null },
    confirmDeletePlanIngredient() {
      this.deletePlanIngredient(this.ingredientForDeletion.id)
      this.ingredientForDeletion = null
    }
  },
}
</script>
