<template>
  <div class="full-slide centered">
    <div class="main-view">
      <splash />
      <login-form />
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm";
import Splash from "@/components/ui/Splash";

export default {
  name: "Home",

  components: {
    LoginForm, Splash
  }
}
</script>
