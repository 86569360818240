<template>
  <div class="form-padded">
    <div v-for="(comp, index) in d" :key="index" class="rule-entry box">
      <div class="entry-inner flexy">
        <a @click="remove(index)" class="danger-btn">x</a>
        <v-select v-model="comp[0]" :options="selectBuckets" :reduce="it => it.index" label="name" :clearable="false" />
        <v-select v-model="comp[2]" :options="compOptions" :reduce="it => it.value" label="label" :clearable="false" />
        <v-select v-model="comp[1]" :options="selectBuckets" :reduce="it => it.index" label="name" :clearable="false" />
        <input v-if="!sources[rule.source] || !sources[rule.source].props" type="text" v-model="comp[3]" />
        <v-select v-if="sources[rule.source] && sources[rule.source].props" v-model="comp[3]" :options="sourceProps" :clearable="false" />
      </div>
    </div>
    <div class="button-row flexy">
      <button class="primary" @click="add()">Add Condition</button>
      <button class="danger" @click="onRemove()">Delete Rule</button>
    </div>
  </div>
</template>

<script>

const compOptions = [
  {label:"Greater Than", value: ">"},
  {label:"Equal or Greater Than", value: ">="},
  {label:"Equals", value: "="},
  {label:"Equal or Smaller Than", value: "<="},
  {label:"Smaller Than", value: "<"},
  {label:"Not Equals", value: "!="},
];

export default {
  name: "BucketCompareRule",
  props: ["rule", "buckets", "sources", "onChange", "onRemove"],

  data() {
    return {
      d : null,
      compOptions,
    }
  },


  mounted() {
    this.d = this.rule.data;
  },

  methods : {
    remove(index) {
      this.d.splice(index, 1);
    },
    add() {
      this.d.push([0,1,">",""])
    }
  },

  computed: {
    sourceProps() {
      const props = [...this.sources[this.rule.source].props];
      if (this.sources[this.rule.source].computed) for (let p in this.sources[this.rule.source].computed) props.push(p)
      return props;
    },
    selectBuckets() {
      return this.buckets.map((it, index) => ({name: it.name, index}))
    }
  },
}
</script>

<style scoped>

</style>
