<template>
  <div class="form-padded">
    <div v-for="(comp, index) in d" :key="index" class="rule-entry box">
      <div class="entry-inner sub-rule">
        <div v-for="(cond, cIndex) in comp" :key="cIndex" class="flexy">
          <a @click="removeCond(index, cIndex)" class="danger-btn">x</a>
          <DataParam :type="cond[0]" :value="cond[1]" :buckets="buckets" :sources="sources" :rule="rule" :inputs="inputs"
                      :on-change="(type, value) => changeParam(index, cIndex, 0, 1, type, value)" />
          <v-select v-model="cond[2]" :options="compOptions" :reduce="it => it.value" label="label" :clearable="false" />
          <DataParam :type="cond[3]" :value="cond[4]" :buckets="buckets" :sources="sources" :rule="rule" :inputs="inputs"
                      :on-change="(type, value) => changeParam(index, cIndex, 3, 4, type, value)" />
          <span v-if="cIndex < comp.length-1">And</span>
          <button v-if="cIndex === comp.length-1" @click="addCond(index)" class="primary">And</button>
        </div>
      </div>
    </div>
    <div class="button-row flexy">
      <button class="primary" @click="add()">Add Condition</button>
      <button class="danger" @click="onRemove()">Delete Rule</button>
    </div>
  </div>
</template>

<script>
import DataParam from "@/components/forms/rules/DataParam";

const compOptions = [
  {label:"Greater Than", value: ">"},
  {label:"Equal or Greater Than", value: ">="},
  {label:"Equals", value: "="},
  {label:"Equal or Smaller Than", value: "<="},
  {label:"Smaller Than", value: "<"},
  {label:"Not Equals", value: "!="},
  {label:"In Array", value: "IN"},
  {label:"Not In Array", value: "NOT_IN"},
];

export default {
  name: "BooleanFilterRule",
  components: {DataParam},
  props: ["rule", "buckets", "sources", "inputs", "onChange", "onRemove"],

  data() {
    return {
      compOptions,
      d : null
    }
  },

  mounted() {
    this.d = this.rule.data;
  },

  methods : {
    remove(index) {
      this.d.splice(index, 1);
      this.onChange(this.d);
    },
    add() {
      this.d.push([['value',1,">","value",0]])
      this.onChange(this.d);
    },
    addCond(index) {
      this.d[index].push(['value',1,">","value",0])
      this.onChange(this.d);
    },
    removeCond(index, cIndex) {
      this.d[index].splice(cIndex, 1);
      if (this.d[index].length === 0) {
        this.d.splice(index, 1);
      }
      this.onChange(this.d);
    },
    changeVal(index, cIndex,prop, value) {
      this.d[index][cIndex][prop] = isNaN(value*1)?value : value*1;
      this.onChange(this.d);
    },
    changeParam(index, cIndex, indexType, indexValue, type, value) {
      this.d[index][cIndex][indexType] = type;
      this.d[index][cIndex][indexValue] = value;
      this.onChange(this.d);
    }
  },
}
</script>

