const
    feet2cm = 30.48,
    inch2cm = 2.54

function BMRtoTDEE(bmr, activityLevel) {
    if (activityLevel > 4) activityLevel = 4
    return bmr * [-1, 1.2, 1.375, 1.55, 1.725][Math.floor(activityLevel)]
}

function statsToBMR(weightKg, heightCm, ageYears, isFemale = false) {
    return (weightKg * 10) + (heightCm * 6.25) - (ageYears * 5) + (isFemale?-161:5)
}

function BMRToMetabolicAge(heightCm, weightKg, age, activityLevel, isFemale = false) {
    const impedence = 800 - (activityLevel * 50);
    if (isFemale && 1>2) {
        return (heightCm * -1.1165) + (weightKg * 1.5784) + (age * 0.4615) + (impedence *  0.0415) + 83.2548;
    } else {
        return (heightCm * -0.7471) + (weightKg * 0.9161) + (age * 0.4184) + (impedence *  0.0517) + 54.2267;
    }
}

function idealWeight (heightCm, isFemale = false) { return (isFemale?45.5:48) + ((isFemale?2.2:2.7) * Math.max(0, heightCm - 5*feet2cm)) / inch2cm }

// function lossAlgorithm(tdee, isFemale = false) {
//     return Math.max(
//         tdee - (isFemale ?
//             tdeeReduction(womenTdeeThreshold, tdee):
//
//             tdeeReduction(menTdeeThreshold, tdee)
//         ),
//         isFemale ? minCaloriesWomen : minCaloriesMen
//     )
// }

function lossAlgorithm(tdee) {
    if (tdee > 2000) return tdee - 700
    else if (tdee > 1800) return tdee - 600
    else return Math.max(1200, tdee - 500)
}

function maintainAlogrithm(tdee) {
    return tdee
}

function gainAlgorithm(tdee) {
    return tdee + 500;
}

function recommendedCalorieIntake(tdee, goal, isFemale = false, planGoals = null) {
    if (planGoals) {
        console.log("Searching Goal:", goal,"Available Goals:", planGoals.map(it => it.slug));
        const planGoal = planGoals.find(it => it.slug === goal);
        if (planGoal) {
            const reductionRules = planGoal['reduction_rules'];
            if (reductionRules) {
                for (let rule of reductionRules) {
                    if (rule.min_tdee && rule.min_tdee > tdee) continue;
                    if (rule.max_tdee && rule.max_tdee < tdee) continue;
                    console.log("Found plan goal:", planGoal.slug, "rule:", rule);
                    if (planGoal.min_calories && tdee < planGoal.min_calories) {
                        console.log("Returning min calories", planGoal.min_calories);
                        return planGoal.min_calories;
                    }
                    return tdee - rule.reduction;
                }
            }
        }
    }

    switch (goal) {
        case 'gain': return gainAlgorithm(tdee, isFemale);
        case 'maintain': return maintainAlogrithm(tdee, isFemale);
        case 'loss': default: return lossAlgorithm(tdee, isFemale);
    }
}

function calculateWeightReduction(weight, planDays) {
    const weeks = Math.floor(planDays/7);
    for (let week = 0; week <= weeks; week++) {
        weight *= 0.99;
    }
    return parseFloat(weight).toFixed(1)*1;
}

function calculateDiameterReduction(planDays) {
    let cm = 0;
    const weeks = Math.floor(planDays/7);
    for (let week = 0; week <= weeks; week++) {
        cm += 0.5;
    }
    return Math.floor(cm);
}
function calculatePantsReduction(planDays) {
    const CM_PER_SIZE = 3;
    let cm = 0;
    const weeks = Math.floor(planDays/7);
    for (let week = 0; week <= weeks; week++) {
        cm += 0.5;
    }
    return Math.floor(Math.floor(cm) / CM_PER_SIZE);
}

function dailyProteinCalories(tdee, goal, activityLevel, weight) {
    const caloriesToProteinGram = 4;
    switch (goal) {
        case 'maintain':
            if (activityLevel >= 4) return Math.round(weight * 1.5 * caloriesToProteinGram);
            else if (activityLevel === 3) return Math.round(weight * 1.4 * caloriesToProteinGram);
            else return Math.round(weight * 1.2 * caloriesToProteinGram);
        case 'balanced':
            if (activityLevel >= 4) return Math.round(weight * 1.6 * caloriesToProteinGram);
            else if (activityLevel === 3) return Math.round(weight * 1.5 * caloriesToProteinGram);
            else return Math.round(weight * 1.4 * caloriesToProteinGram);
        case 'loss':
        default:
            if (activityLevel >= 4) return Math.round(weight * 1.8 * caloriesToProteinGram);
            else if (activityLevel === 3) return Math.round(weight * 1.6 * caloriesToProteinGram);
            else return Math.round(weight * 1.4 * caloriesToProteinGram);
    }
}

function dailyFatCalories(tdee) {
    return tdee * 0.25
}

function dailyCarbCalories(tdee, goal, activityLevel, weight) {
    return tdee - dailyProteinCalories(tdee, goal, activityLevel, weight) - dailyFatCalories(tdee, goal, activityLevel, weight)
}

function calculateWaterDaily(weight) {
    return parseFloat(0.04 * weight).toFixed(1)*1
}

module.exports = {
    statsToBMR,
    BMRtoTDEE,

    recommendedCalorieIntake,
    idealWeight,
    BMRToMetabolicAge,

    dailyProteinCalories,
    dailyFatCalories,
    dailyCarbCalories,

    calculateWeightReduction,
    calculateDiameterReduction,
    calculatePantsReduction,
    calculateWaterDaily,
};
