export default [
    { label: "Name", prop: "name", type: "text", group: "main", inputClass: "diagonal-wrap" },
    { label: "Slug", prop: "slug", type: "text", group: "main" },

    { label: "Calories", prop: "nutrition.calories", type: "number", group: "primary" },
    { label: "Protein (G)", prop: "nutrition.protein_g", type: "number", group: "primary" },
    { label: "Fat (G)", prop: "nutrition.fat_g", type: "number", group: "primary" },
    { label: "Carbs (G)", prop: "nutrition.carbs_g", type: "number", group: "primary" },

    { label: "Sugars (G)", prop: "nutrition.sugars_g", type: "number", group: "secondary" },
    { label: "Fiber (G)", prop: "nutrition.fiber_g", type: "number", group: "secondary" },
    { label: "Saturated Fat (G)", prop: "nutrition.saturated_fat_g", type: "number", group: "secondary" },
    { label: "Trans Fat (G)", prop: "nutrition.trans_fat_g", type: "number", group: "secondary" },
    { label: "Cholesterol (MG)", prop: "nutrition.cholesterol_mg", type: "number", group: "secondary" },
    { label: "Water (G)", prop: "nutrition.water_g", type: "number", group: "secondary" },
    { label: "Alcohol Ethyl (G)", prop: "nutrition.alcohol_ethyl_g", type: "number", group: "secondary" },

    { label: "Sodium (MG)", prop: "nutrition.sodium_mg", type: "number", group: "small", groupTitle: "Minerals" },
    { label: "Calcium (MG)", prop: "nutrition.calcium_mg", type: "number", group: "small" },
    { label: "Magnesium (MG)", prop: "nutrition.magnesium_mg", type: "number", group: "small" },
    { label: "Phosphorus (MG)", prop: "nutrition.phosphorus_mg", type: "number", group: "small" },
    { label: "Potassium (MG)", prop: "nutrition.potassium_mg", type: "number", group: "small" },
    { label: "Zinc (MG)", prop: "nutrition.zinc_mg", type: "number", group: "small" },
    { label: "Iron (MG)", prop: "nutrition.iron_mg", type: "number", group: "small" },
    { label: "Copper (MG)", prop: "nutrition.copper_mg", type: "number", group: "small" },

    { label: "Carotene, Beta (UG)", prop: "nutrition.carotene_beta_ug", type: "number", group: "small 2", groupTitle: "Pigments and Alkaloids" },
    { label: "Carotene, Alpha (UG)", prop: "nutrition.carotene_alpha_ug", type: "number", group: "small 2" },
    { label: "Cryptoxanthin, Beta (UG)", prop: "nutrition.cryptoxanthin_beta_ug", type: "number", group: "small 2" },
    { label: "Lycopene (UG)", prop: "nutrition.lycopene_ug", type: "number", group: "small 2" },
    { label: "Caffeine (MG)", prop: "nutrition.caffeine_mg", type: "number", group: "small 2" },
    { label: "Theobromine (MG)", prop: "nutrition.theobromine_mg", type: "number", group: "small 2" },

    { label: "Vitamin A, RAE (UG)", prop: "nutrition.vitamin_a_rae_ug", type: "number", group: "small 3",groupTitle: "Vitamins" },
    { label: "Vitamin B-6 (MG)", prop: "nutrition.vitamin_b_6_mg", type: "number", group: "small 3" },
    { label: "Vitamin B-12 (UG)", prop: "nutrition.vitamin_b_12_ug", type: "number", group: "small 3" },
    { label: "Vitamin B-12, added (UG)", prop: "nutrition.vitamin_b_12_added_ug", type: "number", group: "small 3" },
    { label: "Vitamin D (D2 + D3) (UG)", prop: "nutrition.vitamin_d_d2_d3_ug", type: "number", group: "small 3" },
    { label: "Vitamin E (alpha-tocopherol) (MG)", prop: "nutrition.vitamin_e_alpha_tocopherol_mg", type: "number", group: "small 3" },
    { label: "Vitamin E, added (MG)", prop: "nutrition.vitamin_e_added_mg", type: "number", group: "small 3" },
    { label: "Vitamin K (phylloquinone) (UG)", prop: "nutrition.vitamin_k_phylloquinone_ug", type: "number", group: "small 3" },
    { label: "Niacin (MG)", prop: "nutrition.niacin_mg", type: "number", group: "small 3" },
    { label: "Riboflavin (MG)", prop: "nutrition.riboflavin_mg", type: "number", group: "small 3" },
    { label: "Thiamin (MG)", prop: "nutrition.thiamin_mg", type: "number", group: "small 3" },
    { label: "Folate, total (UG)", prop: "nutrition.folate_total_ug", type: "number", group: "small 3" },
    { label: "Choline, total (MG)", prop: "nutrition.choline_total_mg", type: "number", group: "small 3" },
    { label: "Folic acid (UG)", prop: "nutrition.folic_acid_ug", type: "number", group: "small 3" },
    { label: "Folate, Food (UG)", prop: "nutrition.folate_food_ug", type: "number", group: "small 3" },
    { label: "Folate, DFE (UG)", prop: "nutrition.folate_dfe_ug", type: "number", group: "small 3" },

    { label: "SFA 4:0", prop: "nutrition.sfa_40_g", type: "number", group: "small 4", groupTitle: "Amino/Fatty Acids" },
    { label: "SFA 6:0", prop: "nutrition.sfa_60_g", type: "number", group: "small 4" },
    { label: "SFA 8:0", prop: "nutrition.sfa_80_g", type: "number", group: "small 4" },
    { label: "SFA 10:0", prop: "nutrition.sfa_100_g", type: "number", group: "small 4" },
    { label: "SFA 12:0", prop: "nutrition.sfa_120_g", type: "number", group: "small 4" },
    { label: "SFA 14:0", prop: "nutrition.sfa_140_g", type: "number", group: "small 4" },
    { label: "SFA 16:0", prop: "nutrition.sfa_160_g", type: "number", group: "small 4" },
    { label: "SFA 18:0", prop: "nutrition.sfa_180_g", type: "number", group: "small 4" },
    { label: "MUFA 16:1", prop: "nutrition.mufa_161_g", type: "number", group: "small 4" },
    { label: "MUFA 18:1", prop: "nutrition.mufa_181_g", type: "number", group: "small 4" },
    { label: "MUFA 22:1", prop: "nutrition.mufa_221_g", type: "number", group: "small 4" },
    { label: "PUFA 18:2", prop: "nutrition.pufa_182_g", type: "number", group: "small 4" },
    { label: "PUFA 18:3", prop: "nutrition.pufa_183_g", type: "number", group: "small 4" },
    { label: "PUFA 20:4", prop: "nutrition.pufa_204_g", type: "number", group: "small 4" },
    { label: "PUFA 2:5 n-3 (EPA)", prop: "nutrition.pufa_25_n_3_epa_g", type: "number", group: "small 4" },
    { label: "PUFA 22:5 n-3 (DPA)", prop: "nutrition.pufa_225_n_3_dpa_g", type: "number", group: "small 4" },
    { label: "PUFA 22:6 n-3 (DHA)", prop: "nutrition.pufa_226_n_3_dha_g", type: "number", group: "small 4" },
    { label: "Fatty acids, total monounsaturated", prop: "nutrition.fatty_acids_total_monounsaturated_g", type: "number", group: "small 4" },
    { label: "Fatty acids, total polyunsaturated", prop: "nutrition.fatty_acids_total_polyunsaturated_g", type: "number", group: "small 4" },

    { label: "Gluten Free", prop: "traits.is_gluten_free", type: "checkbox", group: "small 5", groupTitle: "Traits" },
    { label: "Vegan", prop: "traits.is_vegan", type: "checkbox", group: "small 5" },
    { label: "Paleo", prop: "traits.is_paleo", type: "checkbox", group: "small 5" },
    { label: "Dairy Free", prop: "traits.is_dairy_free", type: "checkbox", group: "small 5" },
    { label: "Ketogenic", prop: "traits.is_ketogenic", type: "checkbox", group: "small 5" },
    { label: "Kosher", prop: "traits.is_kosher", type: "checkbox", group: "small 5" },
    { label: "Vegetarian", prop: "traits.is_vegetarian", type: "checkbox", group: "small 5" },
    { label: "Mediterranean", prop: "traits.is_mediterranean", type: "checkbox", group: "small 5" },

]
