<template>
  <div class="settings-panel">
    <div class="panel-header">
      <p>Meal Types</p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <InfoBox>
            <p>Select the meal types you want in your plan, and sort them to reflect the plan's meal order.</p>
            <span class="bolder">Please note</span> that this has a major effect on how the menu is built.
          </InfoBox>
          <div class="form-field">
            <BooleanList :items="allMealTypes" :selected="mealTypes" prop="name" match="id" :inline="true" @change="setMealType" />
          </div>
          <SortableList :items="addedMealTypes" prop="name" @sort="onDragEnd" />
        </div>
        <div class="side">
          <div class="box">
            <div class="diagonal-wrap">
              <button class="large-button" @click="onSubmit">Save Meal Types</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BooleanList from "@/components/ui/BooleanList";
import InfoBox from "@/components/ui/InfoBox";
import SortableList from "@/components/ui/SortableList";
import {delayedIf} from "@/lib/Async";

export default {
  name: "MealTypes",
  components: {SortableList, InfoBox, BooleanList},
  computed: {
    ...mapGetters(['allPlanMeals', 'allPlanIngredients', 'allPlanMealTypes', 'allMealTypes', 'isLoggedIn']),
    addedMealTypes() { return this.allMealTypes ? this.mealTypes.map(it => this.allMealTypes.find(mt => mt.id === (it.meal_type_id ? it.meal_type_id:it))) : null },
  },

  data() {
    return {
      mealTypes: [],
      dragOrder:[]
    }
  },

  async mounted() {
    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else {
        if (this.allPlanMeals.length <= 0) await this.getPlanMeals();
        if (this.allMealTypes.length <= 0) await this.getMealTypes();
        if (this.allPlanMealTypes.length <= 0) await this.getPlanMealTypes();
        this.mealTypes = [...this.allPlanMealTypes]
      }
      await this.setLoading(false)
    },800)
  },

  methods: {
    ...mapActions(['setLoading','getPlanMeals', 'getPlanIngredients', 'getPlanMealTypes', 'getMealTypes', 'setSuccess', 'setPlanMealTypes']),

    setMealType(type) {
      const index = this.mealTypes.findIndex(it => it === type.id)
      if (index >= 0) {
        this.mealTypes.splice(index, 1);
      }
      else {
        this.mealTypes.push(type.id);
      }
    },
    onDragEnd(data) {
        if (data) {
          const ing = this.mealTypes.splice(data.oldIndex, 1);
          this.mealTypes.splice(data.newIndex, 0, ing[0]);
        }

        // for (let i = 0; i < this.mealTypes.length; i++) {
        //   this.mealTypes[i].sort = i+1;
        // }
    },
    async onSubmit() {
      // this.onDragEnd()
      await this.setPlanMealTypes(this.mealTypes)
      await this.setSuccess("Meal Types saved");
    }
  },
}
</script>
