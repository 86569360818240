<template>
  <div class="ingredients-form">
    <h3><span>Meal Ingredients</span></h3>
    <div class="recipe-ingredients">
      <Draggable v-model="ingredientOrder" handle=".handle-icon" @end="onDragEnd">
      <div class="recipe-ingredient" v-for="ingredient in sortedIngredients" :key="ingredient.id+'_'+(ingredient.ingredient?ingredient.ingredient.id:ingredient.ingredient_id)">
        <div class="handle"><span class="handle-icon"></span></div>
        <div class="serving-amount" v-if="servingSize(ingredient.serving_size_id)">x{{ingredient.serving_amount}}</div>
        <div class="serving-size" v-if="servingSize(ingredient.serving_size_id)">{{ingredient.serving_size_id && servingSize(ingredient.serving_size_id) ? servingSize(ingredient.serving_size_id).name : "-"}}</div>
        <div class="name">{{ingredientData(ingredient).name}}
          <div v-if="!ingredientData(ingredient).id" class="error-pill">Missing Ingredient!</div>
        </div>
        <div>
          <div v-if="ingredient.is_dynamic" class="even">
            <div class="success-btn"></div><span> Dynamic</span>
          </div>
          <div v-if="!ingredient.is_dynamic" class="even">
            <div class="disabled-btn"></div><span> Fixed</span>
          </div>
        </div>
        <div class="amount">{{ingredient.amount_g}} g
          <b v-if="ingredient.serving_amount > 1">({{ingredient.amount_g * ingredient.serving_amount}} g)</b>
        </div>
        <div class="actions" v-if="deleteConfirmation !== ingredient.id && !passive">
          <div class="danger-btn" @click="confirmDelete(ingredient)">x</div>
        </div>
        <div class="confirm-delete flexy" v-if="deleteConfirmation === ingredient.id">
            <span>Really delete this ingredient?</span>
            <button @click="deleteIngredient(ingredient)">Yes</button>
            <button @click="deleteConfirmation = null">No</button>
        </div>
      </div>
      </Draggable>
    </div>
    <div v-if="!ingredients || ingredients.length === 0" class="recipe-ingredient">
      No ingredients
    </div>
    <div class="add-ingredient box" v-if="!passive">
      <h3>Add Ingredient</h3>
      <div class="even">
        <div class="diagonal-wrap">
          <v-select v-model="newIngredient" :options="allIngredients" :reduce="it => it.id" label="name" placeholder="- Select Ingredient -" />
        </div>
        <div v-if="existing(newIngredient)" class="exists-error">This ingredient already exists in this meal</div>
        <div v-if="!existing(newIngredient) && newIngredient && ingredientSizes(newIngredient).length">
          <v-select v-model="newServingSize" :options="ingredientSizeOptions(newIngredient)" :reduce="it => it.id" label="name" placeholder="- Select Serving Size -" />
        </div>
        <div v-if="!existing(newIngredient) && newIngredient && ((newServingSize !== '' && newServingSize !== null) || !ingredientSizes(newIngredient).length)" class="flexy">
          <input v-model="newServingAmount" v-if="newServingSize" :type="ingredientSizes(newIngredient).length?'number':'hidden'" class="unwrap serving-amount" placeholder="Serving Amount" />
          <span v-if="ingredientSizes(newIngredient).length && newServingSize"> x </span>
          <input v-model="newAmountG" type="number" class="unwrap" placeholder="Amount (G)" />
          <input v-model="newDynamic" type="checkbox" /> <span>Dynamic Ingredient (can be replaced)</span>
          <div :class="'diagonal-wrap'+(newAmountG?'':' disabled')">
            <button @click="addIngredient">Add Ingredient</button>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Draggable from 'vuedraggable';
export default {
  name: "MealIngredientForm",
  props: ['locale', 'locale_id','ingredients','passive', 'onSort'],
  components: {Draggable},
  data() {
    return {
      deleteConfirmation: null,
      newIngredient : '',
      newServingSize : '',
      newServingAmount : 1,
      newAmountG : '',
      newDynamic : false,
      id : null,
      ingredientOrder: []
    }
  },

  computed : {
    ...mapGetters(['allServingSizes', 'allIngredients']),
    sortedIngredients() {
      let ing = this.ingredients;
      ing = ing.sort((a,b) => {
        return a.sort < b.sort ? -1 : 1
      });
      return ing;
    }
  },

  methods : {
    ...mapActions(['getServingSizes','getIngredients']),

    confirmDelete(ingredient) {
      this.deleteConfirmation = ingredient.id;
    },

    deleteIngredient(ingredient) {
      this.$emit('delete', ingredient.id)
      this.deleteConfirmation = null;
    },

    existing(id) {
      return !!(this.ingredients.find(it => (it.ingredient && it.ingredient.id === id) || it.ingredient_id === id))
    },

    servingSize(id) {
      return this.allServingSizes.find(it => it.id === id)
    },

    ingredientData(ingredient) {
      if (ingredient.ingredient) ingredient = ingredient.ingredient.id;
      else ingredient = ingredient.ingredient_id;
      return this.allIngredients.find(it => it.id === ingredient) || {}
    },

    ingredientSizes(ingredientId) {
      const ingredient = this.allIngredients.find(it => it.id === ingredientId)
      return ingredient.serving_sizes?this.allServingSizes.filter(size => ingredient.serving_sizes.find(it => it.serving_size_id === size.id)):[]
    },

    ingredientSizeOptions(ingredientId) {
      const ingredient = this.allIngredients.find(it => it.id === ingredientId)
      const sizes = ingredient.serving_sizes?this.allServingSizes.filter(size => ingredient.serving_sizes.find(it => it.serving_size_id === size.id)):[]
      if (sizes.length > 0) sizes.push({ id: 0, name: "(Grams)"})
      return sizes
    },

    addIngredient() {
      if (this.newIngredient && this.newAmountG && this.newServingAmount >= 1) this.$emit('add', this.newIngredient, this.newServingSize || 0, this.newServingAmount, this.newAmountG, this.newDynamic)
      this.newIngredient = this.newServingSize = this.newAmountG = '';
      this.newDynamic = false;
      this.newServingAmount = 1;
    },

    async initData() {
      if (this.allIngredients.length <= 0)await this.getIngredients();
      if (this.allServingSizes.length <= 0) await this.getServingSizes();
    },

    onDragEnd(data) {
      this.$emit('sort', data.oldIndex, data.newIndex)
    }
  },

  mounted() {
    this.initData();
  },

  watch : {
    newIngredient() {
      this.newAmountG = this.newServingSize = ''
    },

    newServingSize() {
      console.log("ohhhh", this.newServingSize)
      if (this.newServingSize === 0) {
        this.newAmountG = 0;
      }

      this.newAmountG = ''
      const servingSize = this.allServingSizes.find(it => it.id === this.newServingSize);
      if (servingSize) {
        this.newAmountG = servingSize.amount_g
      }
    }
  }
}
</script>
