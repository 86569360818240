<template>
  <div class="panel inline">
    <ConfirmModal v-if="mealForDeletion" item-type="Plan Meal" :item-name="mealForDeletion.name" :confirm="confirmDeletePlanMeal" :cancel="cancelDeletePlanMeal" />
    <div class="panel-body sides">
      <div class="side first">
        <div class="panel-header">
          <p>Add Replacements to Plan</p>
          <div class="search-box">
            <div class="diagonal-wrap">
              <input type="text" placeholder="Search All Meals" v-model="mealSearch" />
            </div>
            <div class="close-btn" v-if="mealSearch" @click="() => this.mealSearch = ''"></div>
          </div>
        </div>
        <div class="in-panel">
          <div class="full-scroller in-panel">
            <ListAddForm :items="filteredMeals" :form-component="AddPlanMealForm" :submit="addNewPlanMeal" :meta="{allMealTypes}" />
          </div>
        </div>
      </div>
      <div class="main">
        <ListPanel title="Plan Meal Replacements" :fields="fields" :items="planReplacementMeals" :on-row="goto" :on-delete="askToDeletePlanMeal" export-file="plan_meals.csv" />
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ListPanel from "@/components/ui/ListPanel";
import ConfirmModal from "@/components/ui/ConfirmModal";
import {mealNutrition} from "@/lib/NutritionUtils";
import ListAddForm from "@/components/ui/ListAddForm";
import AddPlanMealForm from '@/components/forms/AddPlanMealForm';
import {actionIf, delayedIfLoading} from "@/lib/Async";


export default {
  name: "PlanMealReplacements",

  components: {ListAddForm, ConfirmModal, ListPanel},

  data() {
    return {
      AddPlanMealForm,
      newPlanMealIsSpecial: false,
      mealForDeletion: null,
      search : "",
      mealSearch: "",
      fields : [
        {label: "Name", prop: "name", primary: true, sortable: true },
        {label: "", prop: "id", target:"errors", primary: true, sortable: true, pill: 'error', format: (id) => this.mealErrors(id) },
        {label: "Original Name", prop: "meal_id", target: "original_name", sortable: true, format : (meal_id) => this.allMeals && this.allMeals.find(it => it.id === meal_id) && this.allMeals.find(it => it.id === meal_id).name },
        {label: "Meal Type", prop: "meal_type_id", target:"meal_type", sortable: true, format : (id) => this.allMealTypes && this.allMealTypes.find(it => it.id === id) && this.allMealTypes.find(it => it.id === id).name},
        {label: "Meal Style", prop: "meal_id", target:"meal_style", sortable: true, format : (id) => this.allMeals && this.allMealStyles && this.allMeals.find(it => it.id === id) && this.allMealStyles.find(it => it.id === this.allMeals.find(it => it.id === id).meal_style_id) ? this.allMealStyles.find(it => it.id === this.allMeals.find(it => it.id === id).meal_style_id).name:'-'},
        {label: "Special", prop: "is_special", boolean: true},
        {label: "Calories", prop: "meal_id", target:"calories", sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'calories')},
        {label: "Protein (g)", prop: "meal_id", target:"protein_g", sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'protein_g')},
        {label: "Fat (g)", prop: "meal_id", target:"fat_g", sortable: true, format : (meal_id) => this.rowNutrition(meal_id, 'fat_g')},
        {label: "% Protein", prop: "meal_id", target:"protein_percent", sortable: true, format : (meal_id) => this.rowPercentage(meal_id, 'protein_g', 4)},
        {label: "% Fat", prop: "meal_id", target:"fat_percent", sortable: true, format : (meal_id) => this.rowPercentage(meal_id, 'fat_g', 9)},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },


  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.allPlanMeals.length, this.getPlanMeals],
          [!this.allRecipes.length, this.getRecipes],
          [!this.allMeals.length, this.getMeals],
          [!this.allPlanIngredients.length, this.getPlanIngredients],
          [!this.allMealTypes.length, this.getMealTypes],
          [!this.allMealStyles.length, this.getMealStyles],
          [!this.allPlanMealStyles.length, this.getPlanMealStyles],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanMeals', 'allMeals','allPlanIngredients', 'allMealTypes','allRecipes','allMealStyles','allPlanMealStyles']),
    planReplacementMeals() {
      let meals = this.allPlanMeals, meal, styles;
      if (this.allPlanMealStyles && this.allMeals) {
        styles = this.allPlanMealStyles.filter(it => it.is_main === true).map(it => it.id)
        meals = meals.filter(it => {
          meal = this.allMeals.find(m => m.id === it.meal_id)
          if (!meal) return true
          return meal.meal_style_id && styles.indexOf(meal.meal_style_id) === -1
        })
      }
      return meals;
    },
    sortedMeals() {
      const sort = this.allMeals instanceof Array ? [...this.allMeals]:[]
      return sort.sort((a,b) => a.name < b.name ? -1:1)
    },
    filteredMeals() {
      let meals = [...this.sortedMeals]
      if (this.mealSearch.length >= 1) meals = meals.filter(it => it.name.toLowerCase().indexOf(this.mealSearch.toLowerCase()) > -1)
      if (this.allPlanMealStyles) {
        let mainStyles = this.allPlanMealStyles.filter(it => it.is_main === true)
        mainStyles = mainStyles.map(it => it.id)
        meals = meals.filter(it => it.meal_style_id && mainStyles.indexOf(it.meal_style_id) === -1)
      }
      return meals
    }
  },

  methods: {
    ...mapActions(['getPlanMeals','getPlanIngredients','setLoading','createPlanMeal','deletePlanMeal','getMeals','getRecipes','getMealTypes','getMealStyles','getPlanMealStyles']),
    rowNutrition(meal_id, prop) {
      let res;
      if (this.allMeals) {
        const target = this.allMeals.find(it => it.id === meal_id);
        if (target) {
          const nutrition = mealNutrition(this.allMeals.find(it => it.id === meal_id), false, this.allPlanIngredients);
          res = nutrition[prop];
        }

        return res;
      }
    },

    rowPercentage(meal_id, prop, factor) {
      let res;
      if (this.allMeals) {
        const target = this.allMeals.find(it => it.id === meal_id);
        if (target) {
          const nutrition = mealNutrition(this.allMeals.find(it => it.id === meal_id), false, this.allPlanIngredients);
          res = (nutrition[prop] * factor / nutrition.calories * 100).toFixed(2);
        }

        return res;
      }
    },

    ingredientExists(ing) {
      let match
      if (ing.ingredient && ing.ingredient.id) {
        match = this.allPlanIngredients.find(it => it.ingredient_id === ing.ingredient.id)
        if (match) return true
      } else {
        match = this.allPlanIngredients.find(it => it.ingredient_id === ing.ingredient_id)
        if (match) return true
      }
    },

    mealErrors(id) {
      if (!this.allPlanMeals.length || !this.allPlanIngredients.length || !this.allRecipes.length) return 0
      let c = 0, recipe, meal = this.allPlanMeals.find(it => it.id === id)
      if (!meal) return 0;
      for (let ing of meal.ingredients) {
        if (!this.ingredientExists(ing)) c++
      }
      for (let rec of meal.recipes) {
        if (!rec || !rec.recipe) continue;
        recipe = this.allRecipes.find(it => it.id === rec.recipe_id)
        for (let ing of recipe.ingredients) {
          if (!this.ingredientExists(ing)) c++
        }
      }
      return c?c+' errors':'';
    },

    goto(meal) {
      this.$router.push('/plan-meal/'+meal.id)
    },

    async addNewPlanMeal(meal_id,name,meal_type_id,is_special) {
      if (!name || !meal_type_id) return false;
      await this.setLoading(true);

      try {
        await this.createPlanMeal({meal_id, meal_type_id, name, is_special})
      }
      catch(e) {
        console.log("Error!")
      }

      await this.setLoading(false);
      await this.getPlanIngredients()
    },
    askToDeletePlanMeal(meal) {
      this.mealForDeletion = meal;
    },
    cancelDeletePlanMeal() { this.mealForDeletion = null },
    confirmDeletePlanMeal() {
      this.deletePlanMeal(this.mealForDeletion.id)
      this.mealForDeletion = null
    }
  },


}
</script>
