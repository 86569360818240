<template>
  <div class="form-group">
    <div class="even form-padded">
      <div>
        <h3>Background Color</h3>
        <div class="diagonal-wrap">
          <input type="text" v-model="iconColor" />
        </div>
        <h3>Choose Existing</h3>
        <div class="color-list">
          <div v-for="(color, index) in colors" :style="{backgroundColor: color}" :key="index" @click="setColor(color)" />
        </div>
      </div>
      <div>
        <h3>Upload New Image</h3>
        <div class="diagonal-wrap">
          <input type="file" name="file" @change="onImage" ref="imageInput" />
        </div>
        <h3>Choose Existing</h3>
        <div class="icon-list">
          <img v-for="(image, index) in images" :src="image" :key="index" @click="setImage(image)" />
        </div>

      </div>
      <div>
        <h3>Preview</h3>
        <div class="preview-box" :style="{backgroundColor: iconColor, backgroundImage: bgImg}">

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IngredientIconForm",
  components: {},
  props: ['icon', 'existing'],
  data() {
    return {
      icons : [],
      iconImage: '',
      iconColor: '',
      iconFile: '',
      bgImg: '',
    }
  },
  methods : {
    onImage() {
      const [file] = this.$refs.imageInput.files
      if (file) {
        this.bgImg = `url(${URL.createObjectURL(file)})`
        this.iconFile = (file)
      }
    },
    setImage(image) {
      this.bgImg = `url(${image})`
      this.iconImage = (image)
    },
    setColor(color) {
      this.iconColor = color;
    }
  },

  computed : {
    colors() {
      const r = [];
      for (let icon of this.icons) if (icon.color && r.indexOf(icon.color)<0) r.push(icon.color)
      return r;
    },
    images() {
      const r = [];
      for (let icon of this.icons) if (icon.image && r.indexOf(icon.image)<0) r.push(icon.image)
      return r;
    }
  },

  mounted() {
    if (this.icon) {
      this.iconImage = this.icon.image;
      this.iconColor = this.icon.color;
      if (this.iconImage) this.bgImg = `url(${this.iconImage})`
    }
    if (this.existing) {
      this.icons = [...this.existing.map(it => ({image: it.image, color: it.color}))]
    }
  },

  watch : {
    iconFile() { this.$emit('update',['file', this.iconFile]) },
    iconImage() { this.$emit('update',['image', this.iconImage]) },
    iconColor() { this.$emit('update',['color', this.iconColor]) },
  }
}
</script>
