<template>
  <div class="search-box">
    <div class="diagonal-wrap">
      <input type="text" :placeholder="'Search All '+name" :value="value" @input="$emit('input', $event.target.value)" />
    </div>
    <div class="close-btn" v-if="value" @click="resetSearch"></div>
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  props: ['value','name','reset'],
  methods : {
    resetSearch() {
      this.$emit('input', '')
    }
  }
}
</script>
