<template>
  <div class="settings-panel">
    <div class="panel-header">
      <p>General Settings</p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <InfoBox v-if="myPlan" :title="myPlan.name+' plan settings'" class="form-field">
            <p>You can set your plan settings here.</p>
            <span class="bold">Be careful!</span> these settings can be extremely fragile, make sure you know what you are doing or you might risk breaking your menus.
          </InfoBox>
          <div class="form-field">
            <label>Editor Title</label>
            <div class="form-input">
              <input v-model="editorTitle" placeholder="Fito Nutrition" type="text" />
            </div>
          </div>

          <InfoBox title="Where to go next?">
            <div class="even fill">
              <router-link to="/plan-settings/meal-types">
                <div class="box interactive">
                  <div class="between">
                    <h3>Meal Types</h3>
                    <div v-if="allPlanMealTypes && allPlanMealTypes.length > 0" class="flexy bold">{{allPlanMealTypes.length}} Meal Types in plan <span class="success-btn"></span></div>
                    <div v-if="!allPlanMealTypes || allPlanMealTypes.length === 0" class="flexy bold">No meal types in plan <span class="danger-btn"></span></div>
                  </div>
                  <p>Define which meals of the day are in your plan.<br />
                    Does your plan work for breakfast, lunch and dinner? does it have other meals? Configure it here.</p>
                </div>
              </router-link>
              <router-link to="/plan-settings/meal-styles">
              <div class="box interactive">
                  <div class="between">
                    <h3>Meal Styles</h3>
                    <div v-if="allPlanMealStyles && allPlanMealStyles.length > 0" class="flexy bold">{{allPlanMealStyles.length}} Meal Styles in plan: ({{mainMealStyles.length}} Main Styles) <span class="success-btn"></span></div>
                    <div v-if="!mainMealStyles || mainMealStyles.length === 0" class="flexy bold">No Main Meal Styles in plan <span class="danger-btn"></span></div>
                  </div>
                  <p>Choose which styles of meals your plan should include when building menus, and which styles of meals
                    should be used to offer as meal replacements. Use this to allow replacing meals by special recipes or
                    different meal styles.
                  </p>
                </div>
              </router-link>
            </div>
            <div class="even">
              <router-link to="/plan-settings/menu-rules">
                <div class="box interactive">
                  <div class="between">
                    <h3>Meal Rules</h3>
                    <div v-if="myPlan && myPlan.rules && myPlan.rules.length > 0" class="flexy bold">{{myPlan && myPlan.rules.length}} Menu Rules in plan<span class="success-btn"></span></div>
                    <div v-if="myPlan && (!myPlan.rules || myPlan.rules.length === 0)" class="flexy bold">No Menu Rules in plan <span class="danger-btn"></span></div>
                  </div>
                    <p>Set the rules by which your plan filters possible results when building menus.<br />
                    You can define nutrition offset threshoulds, dictate the required order of meals by their calories, set
                      a protein-per-kg limit, or any other kind of filter you can imagine here.
                    </p>
                </div>
              </router-link>
              <router-link to="/plan-settings/menu-inputs">
                <div class="box interactive">
                  <div class="between">
                    <h3>Meal Input Parameters</h3>
                    <div v-if="myPlan && myPlan.inputs && myPlan.inputs.length > 0" class="flexy bold">{{myPlan.inputs.length}} Input Parameters configured <span class="success-btn"></span></div>
                    <div v-if="myPlan && (!myPlan.inputs || myPlan.inputs.length === 0)" class="flexy bold">No Input Parameters configured <span class="danger-btn"></span></div>
                  </div>
                  <p>Configure the input parameters required by your plan's protocol to generate menus.<br />
                    These are the variables that are used to get the most relevant menus. They could be calories, weight, macro-nutrients -
                    as long as you can apply a factor on either of these values to convert them calories, fat and protein for sorting.</p>
                </div>
              </router-link>
            </div>
          </InfoBox>

        </div>
        <div class="side">
          <div class="box">
            <div class="diagonal-wrap">
              <button class="large-button" @click="onSubmit">Save Settings</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InfoBox from "@/components/ui/InfoBox";
import {actionIf} from "@/lib/Async";

export default {
  name: "GeneralSettings",
  components: {InfoBox},

  data() {
    return {
      editorTitle: ''
    }
  },

  async mounted() {
    await actionIf([
      [!this.myPlan, this.getMyPlan],
      [!this.allPlanMealTypes.length, this.getPlanMealTypes],
      [!this.allPlanMealStyles.length, this.getPlanMealStyles],
    ])

    this.editorTitle = this.myPlan.editor_title;
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'isLoading', 'success', 'appError', 'currentUser','currentPlan','myPlan','allPlanMealTypes', 'allPlanMealStyles']),
    mainMealStyles() { return this.allPlanMealStyles.filter(it => it.is_main)},
    replacementMealStyles() { return this.allPlanMealStyles.filter(it => it.is_main)}
  },

  methods : {
    ...mapActions(['logout', 'setCurrentPlan','setEditorTitle','setSuccess','getMyPlan','getPlanMealTypes','getPlanMealStyles']),
    async onSubmit() {
      await this.setEditorTitle([this.currentPlan, this.editorTitle])
      await this.setSuccess("Plan Settings saved");
    },
  },
}
</script>
