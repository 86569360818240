<template>
  <div class="settings-panel">
    <div class="panel-header">
      <p>Menu Input parameters</p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <InfoBox title="Setting your input parameters">
            <p>The menu input parameters define how calculator statistics would be used to generate menus.</p>
            <p>Each input parameter represents a distinct parameter that is used by the algorithm to generate tailored menus.</p>
            <p>These inputs are then shown as fields in the <router-link to="/plan-menu">Menu Builder</router-link>, and are <span class="bold">expected to be included</span>
              in each menu request from your production application.</p>
          </InfoBox>

          <div v-if="myPlan">
            <div class="form-group" v-for="(input, index) in inputs" :key="index">
              <div class="form-group-content flexy">
                <a class="danger-btn" @click="removeInput(index)">x</a>
                <span>{{input}}</span>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form-group-content flexy">
              <span>Add a new Parameter</span>
              <div class="diagonal-wrap wrap-bottom">
                <input type="text" v-model="newInputName" placeholder="Input Parameter name"/>
              </div>
              <div :class="'diagonal-wrap'+(newInputName?'':' disabled')" @click="onAddInput">
                <button>Add Input Parameter</button>
              </div>
            </div>
          </div>

          <InfoBox title="Derived Sorting">
            <p>The sorting algorithm needs to be able to resolve values for Calories, Protein and Fat.</p>
            <p>Choose which of your input parameters correlates to each of those values, or one from which the value can be derived by applying a factor.</p>
            <p>Then, choose the initial sorting force of every value. 0 means the sorting disregards this value, 10 makes it ten times more powerful.</p>
          </InfoBox>

          <div class="form-group">
            <div class="form-group-inner">
              <div class="even">
                <div class="box">
                  <div class="flexy">
                    <span :class="sorting.calories.input && !isNaN(sorting.calories.factor) ? 'success-btn':'danger-btn'"></span>
                    <span>Calories</span>
                    <v-select v-model="sorting.calories.input" :options="inputs" />
                    <input type="number" v-model="sorting.calories.factor" />
                  </div>
                </div>
                <div class="box">
                  <div class="flexy">
                    <span :class="sorting.protein.input && !isNaN(sorting.protein.factor) ? 'success-btn':'danger-btn'"></span>
                    <span>Protein</span>
                    <v-select v-model="sorting.protein.input" :options="inputs" />
                    <input type="number" v-model="sorting.protein.factor" />
                  </div>

                </div>
                <div class="box">
                  <div class="flexy">
                    <span :class="sorting.fat.input && !isNaN(sorting.fat.factor) ? 'success-btn':'danger-btn'"></span>
                    <span>Fat</span>
                    <v-select v-model="sorting.fat.input" :options="inputs" />
                    <input type="number" v-model="sorting.fat.factor" />
                  </div>

                </div>
              </div>
              <SortingPanel :data="sorting" :raw="true" :on-change="onSortingChange" />
            </div>
          </div>

        </div>
        <div class="side">
          <div class="box">
            <div class="diagonal-wrap">
              <button class="large-button" @click="onSubmit">Save Input Parameters</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import InfoBox from "@/components/ui/InfoBox";
import {mapActions, mapGetters} from "vuex";
import SortingPanel from "@/components/menu/SortingPanel";
export default {
  name: "MenuInputs",
  components: {SortingPanel, InfoBox},
  computed: {
    ...mapGetters(['currentPlan','myPlan']),
  },
  data() {
    return {
      newInputName : "",
      inputs: [],
      sorting: {
        calories : { factor: 1 },
        protein : { factor: 1 },
        fat : { factor: 1 },
      }
    }
  },
  methods : {
    ...mapActions(['getPlans','setPlanInputs','setSuccess','getMyPlan']),
    onAddInput() {
      if (this.newInputName) {
        this.inputs.push(this.newInputName)
        this.newInputName = ''
      }
    },
    onSortingChange(prop, value) {
      this.$set(this.sorting, prop, value)
    },
    removeInput(index) {
      this.inputs.splice(index, 1);
    },
    async onSubmit() {
      await this.setPlanInputs([this.currentPlan, this.inputs, this.sorting])
      await this.setSuccess("Saved Input Parameters")
    }
  },
  async mounted() {
    if (!this.myPlan) await this.getMyPlan()

    if (this.myPlan && this.myPlan.inputs) this.inputs = [...this.myPlan.inputs]
    if (this.myPlan && this.myPlan.sorting) {
      for (let s in this.myPlan.sorting) { this.$set(this.sorting,s,this.myPlan.sorting[s]) }
    }
  }
}
</script>
