<template>
  <div class="panel inline">
    <div class="panel-body sides">
      <div class="side first">
        <div class="panel-header">
          <p>Nutrition Settings</p>
        </div>
        <div class="panel-body">
          <div class="full-scroller in-panel">
            <NutritionPanel v-if="myPlan" :goals="goals" :data="inputs" :on-change="onNutritionChange" />
            <RulesSwitcher v-if="myPlan" :rules="myPlan.rules" @change="onRuleToggle" />
            <SortingPanel :on-change="onSortingChange" :data="myPlan?myPlan.sorting:{}" />
            <PreferencesPanel :on-change="onMenuPreferences" :reset-preferences="onResetPreferences" />
            <BucketSwitcher @reset="runTree" />
          </div>
        </div>
      </div>
      <div class="main">
        <div class="panel-header">
          <p>Menu Result</p>
        </div>
        <div class="panel-bod">
          <div class="full-scroller in-panel">
            <MealsMenu :meals="meals" :no-results="noResults" :meal-alternatives="mealAlternatives" :meal-alternatives-goals="mealAlternativesGoals" :replace-meal="replaceMeal" />
            <NutritionResults :nutrition="nutrition" :calories="planSortBy('calories')" :protein="planSortBy('protein')" :fat="planSortBy('fat')" />
            <PerformanceResults :meta="meta" :calories="planSortBy('calories')" :protein="planSortBy('protein')" :fat="planSortBy('fat')" :nutrition="nutrition" />
            <div class="info-box" v-if="generating">Generating Results...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NutritionUtils, {
  applyPreferencesToMeals,
  closestMealIndex,
  getAvailableMeals,
  getDailyNutrition,
  mealNutrition,
  mealsNutrition,
  menuMealReplacements,
} from '@/lib/NutritionUtils';
import Single from "@/lib/Single";
import NutritionPanel from "@/components/menu/NutritionPanel";
import PreferencesPanel from "@/components/menu/PreferencesPanel";
import SortingPanel from "@/components/menu/SortingPanel";
import MealsMenu from "@/components/menu/MealsMenu";
import NutritionResults from "@/components/menu/NutritionResults";
import PerformanceResults from "@/components/menu/PerformanceResults";
import {mapActions, mapGetters} from "vuex";
import NuTree from "@/lib/nutree/NuTree";
import Config from "@/lib/nutree/Config";
import RulesSwitcher from "@/components/menu/RulesSwitcher";
import BucketSwitcher from "@/components/menu/BucketSwitcher";
import {actionIf, delayedIfLoading} from "@/lib/Async";

const sleep = (ms) => new Promise((res) => setTimeout(() => res(), ms))

export default {
  name: "MenuBuilder",

  components : {
    BucketSwitcher,
    RulesSwitcher, MealsMenu, NutritionPanel, PreferencesPanel, NutritionResults, PerformanceResults, SortingPanel },

  data : function() {
    return {
      inputs : {
        // calories: 1550,
        // weight: 55,
      },
      hardLimits : {

      },
      meals : null,
      nutrition: null,
      noResults : false,
      mealAlternatives: null,
      mealAlternativesGoals : null,
      generating: false,
      meta : null,
      tree : null,
      options: Config,

      sorting : null,

      mealsDb : [],
    }
  },

  async mounted() {

    await delayedIfLoading(!this.isLoggedIn, actionIf([
      [!this.myPlan, this.getMyPlan],
      [!this.allMeals.length, this.getMeals],
      [!this.allIngredients.length, this.getIngredients],
      [!this.allPrefGroups.length, this.getPrefGroups],
      [!this.allPlanGoals.length, this.getPlanGoals],
      [!this.allPlanMeals.length, this.getPlanMeals],
      [!this.allPlanIngredients.length, this.getPlanIngredients],
      [!this.allMealTypes.length, this.getMealTypes],
      [!this.allPlanMealTypes.length, this.getPlanMealTypes],
      [!this.allPlanMealStyles.length, this.getPlanMealStyles],
    ]), this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))

    await this.setLoading(true)
    this.generating = true;
    console.log("eh...")
    await sleep(300)
    console.log("loaded", this.allPlanIngredients.length, this.allPlanMeals.length, this.allPlanGoals.length)
    NutritionUtils.setIngredientsDb([...this.allPlanIngredients])
    this.mealsDb = [...this.mainPlanMeals]

    const planMealTypes = this.allPlanMealTypes.map(it => this.allMealTypes.find(mt => mt.id === it))
    let tree = new NuTree([], {
      data : this.mealsDb,
      designator: this.options.designator,
      buckets : planMealTypes,
      sources : this.options.sources,
      rules : this.myPlan.rules,
      sorting: this.options.sorting,
    });
    Single.set('tree', tree);

    setTimeout(async () => {
      console.log("mealsLength", this.allMeals.length)
      await this.runTree()
    },1)
  },

  computed: {
    ...mapGetters(['allPlanMeals', 'allPlanIngredients', 'allPrefGroups','allPlanGoals', 'allIngredients', 'allMeals', 'allMealTypes', 'myPlan', 'allPlanMealTypes', 'allPlanMealStyles', 'allMealTypes', 'currentPlan','isLoggedIn']),
    mainPlanMeals() {
      let meals = this.allPlanMeals, meal
      if (this.allPlanMealStyles && this.allMeals) {
        let mainStyles = this.allPlanMealStyles.filter(it => it.is_main === true)
        mainStyles = mainStyles.map(it => it.id)
        meals = meals.filter(it => {
          meal = this.allMeals.find(m => m.id === it.meal_id)
          if (!meal) return true
          return !meal.meal_style_id || mainStyles.indexOf(meal.meal_style_id) > -1
        })
      }
      return meals;
    },

    goals() {
      return this.allPlanGoals.map(it => ({id: it.slug, ...it}))
    },

    fullPlanMeals() {
      let meals = this.allPlanMeals
      if (this.allPlanMeals && this.allMeals) {
        meals = meals.map(it => ({...it, meal: this.allMeals.find(m => m.id === it.meal_id)}))
      }
      return meals
    },
  },

  methods : {
    ...mapActions(['getPlanMeals', 'getPlanIngredients','getPrefGroups','getPlanGoals', 'getIngredients','getMeals','getMealTypes','getMyPlan','getPlanMealTypes', 'getPlanMealStyles', 'getMealTypes', 'setLoading']),

    planSorting() {
      let sort = {};
      if (this.myPlan.sorting) {
        if (this.myPlan.sorting.calories) { sort.calories = this.inputs[this.myPlan.sorting.calories.input] * this.myPlan.sorting.calories.factor }
        // if (this.myPlan.sorting.protein) { sort.protein = this.inputs[this.myPlan.sorting.protein.input] * this.myPlan.sorting.protein.factor }
        // if (this.myPlan.sorting.fat) { sort.fat = this.inputs[this.myPlan.sorting.fat.input] * this.myPlan.sorting.fat.factor }
        if (this.inputs.calories) {
          sort.calories = this.inputs.calories
          sort.protein = this.inputs.protein * 4
          sort.fat = this.inputs.fat * 9
        }
        sort.powerCal = this.myPlan.sorting.powerCal || 1
        sort.powerProtein = this.myPlan.sorting.powerProtein || 1
        sort.powerFat = this.myPlan.sorting.powerFat || 1
      }
      return sort;
    },

    planSortBy(prop) {

      if (!this.myPlan || !this.myPlan.sorting) return 0;
      switch (prop) {
        // case 'calories': return this.inputs[this.myPlan.sorting.calories.input] * this.myPlan.sorting.calories.factor;
        // case 'protein': return this.inputs[this.myPlan.sorting.protein.input] * this.myPlan.sorting.protein.factor;
        // case 'fat': return this.inputs[this.myPlan.sorting.fat.input] * this.myPlan.sorting.fat.factor;
        case 'calories': return this.inputs.calories;
        case 'protein': return this.inputs.protein * 4;
        case 'fat': return this.inputs.fat * 9;
      }
    },

    replaceMeal(index, newMeal) {
      const oldMeal = {...this.meals[index]}
      this.$set(this.meals, index, newMeal)

      const repIndex = this.mealAlternatives[index].findIndex(it => it.id === newMeal.id)
      this.mealAlternatives[index].splice(repIndex, 1, oldMeal)

      this.nutrition = mealsNutrition(this.meals);
    },

    async runTree(reset = false, data = null) {
      await this.setLoading(true);
      this.generating = true;
      setTimeout(async () => {
        const tree = Single.get('tree');
        const time = new Date().getTime();

        if (reset) {
          if (data) tree.options.data = data;
          tree.reset();
        }

        if (Object.keys(this.inputs).length <= 0 || !this.inputs['calories']) {
          // abort
          this.generating = false;
          await this.setLoading(false)
          return;
        }

        let res = tree.run({hardLimits: this.hardLimits}, this.inputs);

        // resolve sort factors
        const sort = this.planSorting()
        if (!this.sorting) {
          this.sorting = {
            powerCal : sort.powerCal || 1,
            powerProtein : sort.powerProtein || 1,
            powerFat : sort.powerFat || 1,
          }
        }

        res = tree.sortResults(res, {
              calories: sort.calories, protein: sort.protein, fat: sort.fat,
              powerCal: this.sorting.powerCal, powerProtein: this.sorting.powerProtein, powerFat: this.sorting.powerFat
            }
        );

        this.generating = false;

        if (res.length === 0) {
          this.noResults = true;
        }
        else {
          this.noResults = false;
          const mealData = getAvailableMeals(tree.buckets)
          this.meals = tree.extractResult(mealData, res[0]);
          this.nutrition = getDailyNutrition(mealData, res[0]);
        }

        if (this.meals) {
          let replacements = this.meals ? menuMealReplacements(this.allPlanMealStyles, this.meals, this.fullPlanMeals, this.myPlan.meal_rep_offsets) : []

          // limit alternative groups
          const replacementGroups = {}
          for (let r = 0; r < replacements.length; r++) {
            for (let s = 0; s < replacements[r].length; s++) {
              if (replacements[r][s].meal.rep_group) {
                if (!replacementGroups[r]) replacementGroups[r] = {};
                if (!replacementGroups[r][replacements[r][s].meal.rep_group]) replacementGroups[r][replacements[r][s].meal.rep_group] = []
                replacementGroups[r][replacements[r][s].meal.rep_group].push(s)
              }
            }
          }

          let candidates, closest, nut;

          // replacement group filtering
          if (Object.keys(replacementGroups).length > 0) {
            for (let r in replacementGroups) {
              candidates = []
              for (let grp in replacementGroups[r]) {
                if (replacementGroups[r][grp].length <= 1) continue;
                replacementGroups[r][grp].forEach(index => {
                  candidates.push(replacements[r][index])
                })
                nut = mealNutrition(this.meals[r])
                closest = closestMealIndex(candidates, nut.calories, nut.protein_g, nut.fat_g)
                let added = false
                for (let gi = replacementGroups[r][grp].length - 1; gi >= 0; gi--) {
                  if (added) {
                    replacements[r].splice(replacementGroups[r][grp][gi], 1)
                  } else {
                    replacements[r].splice(replacementGroups[r][grp][gi], 1, candidates[closest])
                    added = true
                  }
                }
              }
            }
          }

          this.mealAlternativesGoals = this.meals.map(it => {
            const n = mealNutrition(it)
            return {calories: n.calories, protein_g: n.protein_g, fat_g: n.fat_g}
          })

          this.mealAlternatives = replacements;
        }

        this.meta = {
          time: new Date().getTime() - time,
          initial: tree.seeds,
          valid: res.length,
          results: tree.getData(res, 'nutrition'),
        }
        await this.setLoading(false)
      }, 10)
    },

    onNutritionChange(prop, val) {
      if (val instanceof String && val.match(/^\d+$/)) val = val*1;
      this.$set(this.inputs, prop, val)
      this.configureInputs()
      this.runTree()
    },

    configureInputs() {
      if (this.inputs['calories']) {
        const goal = this.inputs['goal']
        if (goal) {

          if (goal.min_calories) {
            this.hardLimits = {
              nutrition : {
                calories: {min: goal.min_calories}
              }
            }
          }

          if (goal.nutrition_goals) {
            let nGoals = goal.nutrition_goals.sort((a,b) => a.activityLevel - b.activityLevel)
            for (let g of nGoals) {
              switch (g.nutrient) {
                case 'protein':
                  if (!g.activityLevel || g.activityLevel*1 <= this.inputs['activityLevel']*1) {
                    console.log('---',g.targetValue, this.inputs['weight'])
                    if (g.type === 'g')  this.$set(this.inputs,'protein', g.targetValue * this.inputs['weight'])
                    else if (g.type === '%') this.$set(this.inputs,'protein', this.inputs['calories'] * g.targetValue / 100 / 4)
                  }
                  break
                case 'fat':
                  if (!g.activityLevel || g.activityLevel*1 <= this.inputs['activityLevel']*1) {
                    if (g.type === 'g')  this.$set(this.inputs,'fat',g.targetValue * this.inputs['weight'])
                    else if (g.type === '%')  this.$set(this.inputs,'fat',this.inputs['calories'] * g.targetValue / 100 / 9)
                  }
                  break;
              }
            }
          }
          console.log("inputs", this.inputs)
        }
      }
    },

    onSortingChange(prop, val) {
      switch (prop) {
        case 'powerCal': this.$set(this.sorting, "powerCal", val*1); break;
        case 'powerProtein': this.$set(this.sorting, "powerProtein", val*1); break;
        case 'powerFat': this.$set(this.sorting, "powerFat", val*1); break;
      }
      this.runTree()
    },

    onMenuPreferences(pref) {
      console.log("pref", pref)
      this.mealsDb = this.mainPlanMeals && this.allPrefGroups ? applyPreferencesToMeals([...this.mainPlanMeals], pref, this.allPrefGroups) : [];

      this.runTree(true, this.mealsDb)
    },

    onResetPreferences() {
      this.mealsDb = [...this.mainPlanMeals]
      this.runTree(true, this.mealsDb)
    },

    async onRuleToggle(index) {
        if (this.myPlan.rules[index].inactive) delete this.myPlan.rules[index].inactive
        else this.myPlan.rules[index].inactive = true

      const planMealTypes = this.allPlanMealTypes.map(it => this.allMealTypes.find(mt => mt.id === it))

      let tree = new NuTree([], {
          data : this.mealsDb,
          designator: this.options.designator,
          buckets : planMealTypes,
          sources : this.options.sources,
          rules : this.myPlan.rules,
          sorting: this.options.sorting,
        });
        Single.set('tree', tree);

        await this.runTree()
    }
  },

}

</script>
